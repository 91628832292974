import { SET_ACCESSORIES_RATE, SET_HOME_ASSETS_RATES, SET_RATES_LOADING_FLAG, SET_ACCESSORIES_RATE_LOADING } from '../aem-core-components/actions/constants';
import { useCartState } from '../contexts/cart';
import { useFilterState } from '../components/cap';
import { getInventoryPcs } from '../components/cap/utils/atputils';
import { useAtp } from '../components/cap/hooks/useATP';
import { cacheItemsRatesLimit, getProjectsFromCookie, splitArrayByIndex } from '../components/global/utils/commonUtils';
import { logError } from '../components/global/utils/logger';
import { useAwaitQuery } from '../aem-core-components/utils/hooks';
import { getProductList } from '../aem-core-components/actions/product/actions';
import { getRatesByOwnedPc } from '../components/pdp/api/getRates';
import { usePdpState } from '../contexts/pdp/pdpContext';
import { useCheckUser } from './useCheckUser';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import QUERY_PRODUCT_LIST from '../aem-core-components/queries/query_product_list';
import { ENV_CONFIG } from '../constants/envConfig';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { PDP_GET_RATES } from '../contexts/pdp/constants';

/**
 * the hook encompasses all logic related to pricing calculations
 */
export const usePricing = () => {
    const [{ userAccount }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const [{ item }, pdpDispatch] = usePdpState();
    const productListQuery = useAwaitQuery(QUERY_PRODUCT_LIST);

    const userType = useCheckUser();
    const { handleATPCart } = useAtp();

    const findNearestPcPLP = (item, nearbyPCs = []) => {
        try {
            const pcAvailability = getInventoryPcs(item?.additionalFields?.ec_pc_inventory);
            return findNearestPc(pcAvailability, nearbyPCs);
        } catch (error) {
            logError(error, false, 'findNearestPcPLP', [item, nearbyPCs]);
        }
    };

    const findNearestPcP2P = (item, nearbyPCs = []) => {
        try {
            const pcAvailability = getInventoryPcs(item?.ec_pc_inventory);
            return findNearestPc(pcAvailability, nearbyPCs);
        } catch (error) {
            logError(error, false, 'findNearestPcP2P', [item, nearbyPCs]);
        }
    };

    const findNearestPcPDP = (item, nearbyPCs = []) => {
        try {
            const pcAvailabilityList = getInventoryPcs(item?.ec_pc_inventory);
            if (Array.isArray(nearbyPCs)) {
                if (item?.pc_availability === true) {
                    const foundPC = nearbyPCs?.find(pc => pcAvailabilityList?.includes(pc));
                    if (foundPC) {
                        return parseInt(foundPC?.split('_')[1]) || -1;
                    } else {
                        return parseInt(nearbyPCs[0]?.split('_')[1]) || -1;
                    }
                }
            }
            return -1;
        } catch (error) {
            logError(error, false, 'findNearestPcPDP', [item, nearbyPCs]);
            return -1;
        }
    };

    const findNearestPcHomePage = (item, nearbyPCs = []) => {
        try {
            const pcAvailabilityList = getInventoryPcs(item?.ec_pc_inventory);
            if (Array.isArray(nearbyPCs)) {
                const foundPC = nearbyPCs?.find(pc => pcAvailabilityList?.includes(pc));
                if (foundPC) {
                    return parseInt(foundPC?.split('_')[1]) || -1;
                } else {
                    return parseInt(nearbyPCs[0]?.split('_')[1]) || -1;
                }
            }
            return -1;
        } catch (error) {
            logError(error, false, 'findNearestPcHomePage', [item, nearbyPCs]);
            return -1;
        }
    };

    const getOwnedPcArrayPLP = products => {
        try {
            const ownedPcs = [];
            const ownedPcsItemsMap = {};
            const unavailablePcs = [];
            const productArr = [];
            const ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];

            products?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const plpItem = item?.additionalFields;
                const nearbyPc = overridePC ? overridePC : findNearestPcPLP(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    // will be used later for unavailable items
                    // Daily condition added to check for cart items
                    if (
                        ratesFromStorage[plpItem?.ec_skus[0]]?.ownedPc != nearbyPc ||
                        !ratesFromStorage[plpItem?.ec_skus[0]]?.daily
                    ) {
                        ownedPcs.push({ pc: nearbyPc, productId: plpItem?.ec_skus[0] });
                        ownedPcsItemsMap[plpItem?.ec_skus[0]] = nearbyPc;
                    }
                } else {
                    unavailablePcs.push({ nearbyPc, sku: plpItem?.ec_skus[0] });
                }
                productArr.push(plpItem?.ec_skus[0]);
            });
            return { ownedPcs, ownedPcsItemsMap, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayPLP', [products]);
        }
    };

    const getOwnedPcArraySRP = products => {
        try {
            const ownedPcs = [];
            const ownedPcsItemsMap = {};
            const unavailablePcs = [];
            const productArr = [];
            const ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];

            products?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const srpItem = item?.raw;
                const nearbyPc = overridePC ? overridePC : findNearestPcSRP(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    // will be used later for unavailable items
                    // Daily condition added to check for cart items
                    if (
                        ratesFromStorage[srpItem?.ec_skus[0]]?.ownedPc != nearbyPc ||
                        !ratesFromStorage[srpItem?.ec_skus[0]]?.daily
                    ) {
                        ownedPcs.push({ pc: nearbyPc, productId: srpItem?.ec_skus[0] });
                        ownedPcsItemsMap[srpItem?.ec_skus[0]] = nearbyPc;
                    }
                } else {
                    unavailablePcs.push({ nearbyPc, sku: srpItem?.ec_skus[0] });
                }
                productArr.push(srpItem?.ec_skus[0]);
            });
            return { ownedPcs, ownedPcsItemsMap, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArraySRP', [products]);
        }
    };

    const getOwnedPcArrayAlternateItems = products => {
        try {
            const ownedPcs = [];
            const unavailablePcs = [];
            const productArr = [];
            const ownedPcsItemsMap = {};
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];
            const ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            products?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const nearbyPc = overridePC ? overridePC : findNearestPcAlternateInventory(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    // will be used later for unavailable items
                    // Daily condition added to check for cart items
                    if (
                        ratesFromStorage[item?.catclass]?.ownedPc != nearbyPc ||
                        !ratesFromStorage[item?.catclass]?.daily
                    ) {
                        ownedPcs.push({ pc: nearbyPc, productId: item?.catclass });
                        ownedPcsItemsMap[item?.catclass] = nearbyPc;
                    }
                } else {
                    unavailablePcs.push({ nearbyPc, sku: item?.catclass });
                }
                productArr.push(item?.catclass);
            });
            return { ownedPcs, ownedPcsItemsMap, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayAlternateItems', [products]);
        }
    };

    const getOwnedPcArrayHomePage = products => {
        try {
            const ownedPcs = [];
            const ownedPcsItemsMap = {};
            const unavailablePcs = [];
            const productArr = [];
            const ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];

            products?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const nearbyPc = overridePC ? overridePC : findNearestPcHomePage(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    // will be used later for unavailable items
                    // Daily condition added to check for cart items
                    if (
                        ratesFromStorage[item?.catclass]?.ownedPc != nearbyPc ||
                        !ratesFromStorage[item?.catclass]?.daily
                    ) {
                        ownedPcs.push({ pc: nearbyPc, productId: item?.catclass });
                        ownedPcsItemsMap[item?.catclass] = nearbyPc;
                    }
                } else {
                    unavailablePcs.push({ nearbyPc, sku: item?.catclass });
                }
                productArr.push(item?.catclass);
            });
            return { ownedPcs, ownedPcsItemsMap, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayHomePage', [products]);
        }
    };

    const getOwnedPcArrayP2P = products => {
        try {
            const ownedPcs = [];
            const ownedPcsItemsMap = {};
            const unavailablePcs = [];
            const productArr = [];
            const ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];

            products?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const nearbyPc = overridePC ? overridePC : findNearestPcP2P(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    // will be used later for unavailable items
                    if (ratesFromStorage[item?.catclass]?.ownedPc != nearbyPc) {
                        ownedPcs.push({ pc: nearbyPc, productId: item?.catclass });
                        ownedPcsItemsMap[item?.catclass] = nearbyPc;
                    }
                } else {
                    unavailablePcs.push({ nearbyPc, sku: item?.catclass });
                }
                productArr.push(item?.catclass);
            });
            return { ownedPcs, ownedPcsItemsMap, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayP2P', [products]);
        }
    };

    const findNearestPcSRP = (item, nearbyPCs = []) => {
        try {
            const pcAvailability = getInventoryPcs(item?.raw?.ec_pc_inventory);
            return findNearestPc(pcAvailability, nearbyPCs);
        } catch (error) {
            logError(error, false, 'findNearestPcSRP', [item, nearbyPCs]);
        }
    };

    const findNearestPcAlternateInventory = (item, nearbyPCs = []) => {
        try {
            const pcAvailability = getInventoryPcs(item?.inventory);
            return findNearestPc(pcAvailability, nearbyPCs);
        } catch (error) {
            logError(error, false, 'findNearestPcAlternateInventory', [item, nearbyPCs]);
        }
    };

    const findNearestPc = (pcAvailability, nearbyPCs = []) => {
        try {
            if (Array.isArray(nearbyPCs)) {
                const foundPC = nearbyPCs?.find(pc => pcAvailability?.includes(pc));
                if (foundPC) {
                    return parseInt(foundPC?.split('_')[1]) || -1;
                } else {
                    // when the owned pc list is empty, will return the first pc from the nearbyPCs
                    return parseInt(nearbyPCs?.[0]?.split('_')[1]) || -1;
                }
            }
            return -1;
        } catch (error) {
            logError(error, false, 'findNearestPc', [pcAvailability, nearbyPCs]);
            return -1;
        }
    };

    const getJobSite = () => {
        try {
            const projectCookies = getProjectsFromCookie();
            const isEnterAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
                ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
                : false;
            if (userType === USER_TYPE.CREDIT && !isEnterAddress) {
                return projectDetails?.selectedProjectJobId || projectCookies?.CurrentJobSite;
            }
        } catch (error) {
            logError(error, false, 'getJobSite');
        }
    };

    const getRatesApiCustJobsitePayload = () => {
        try {
            const customerNumber =
                userType === USER_TYPE.CREDIT ? userAccount?.accountNumber?.toString() : viewCart?.customerNumber;
            const jobSite = getJobSite();
            return { customerNumber, jobSite };
        } catch (error) {
            logError(error, false, 'getRatesApiCustJobsitePayload');
        }
    };
    //doesnt include session storage
    const getProductPricesByOwnedPcs = async (ownedPcs = [], ownedPcsItemsMap = {}) => {
        try {
            const { customerNumber, jobSite } = getRatesApiCustJobsitePayload();
            if (ownedPcs?.length > 0) {
                dispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: true
                });
                const { data, error } = await getRatesByOwnedPc(customerNumber, jobSite, ownedPcs);
                updateRatesForProducts(data?.data, ownedPcsItemsMap, error);
            }
        } catch (error) {
            logError(error, false, 'getProductPricesByOwnedPcs', [ownedPcs]);
        }
    };

    const getProductPricesByOwnedPcsPDP = async (ownedPcs = []) => {
        try {
            const { customerNumber, jobSite } = getRatesApiCustJobsitePayload();
            if (ownedPcs?.length > 0) {
                pdpDispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: true
                });
                const { data, error } = await getRatesByOwnedPc(customerNumber, jobSite, ownedPcs);
                updateRatesForProductsPDP(data?.data, error);
            }
        } catch (error) {
            logError(error, false, 'getProductPricesByOwnedPcsPDP', [ownedPcs]);
        }
    };


    const updateRatesForProducts = (ratesObj = {}, ownedPcsItemsMap = {}, error) => {
        try {
            let updatedAssets = {};
            let ratesAndProductid = {};
            let ratesFromStorage =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            const storageItemsRateLength = Object.keys(ratesFromStorage)?.length;
            if (!error) {
                ratesObj?.items.map(ratesObject => {
                    const prodID = ratesObject?.productId;
                    const { daily, minimum, monthly, weekly } = ratesObject?.rates?.suggestedRates;
                    const rates = {
                        daily: Number(daily) || 0,
                        minimum: Number(minimum) || 0,
                        monthly: Number(monthly) || 0,
                        weekly: Number(weekly) || 0,
                        ownedPc: ownedPcsItemsMap[prodID]
                    };
                    ratesAndProductid[prodID] = rates;
                });
                /* Update Rates from API and storage if it is between specified range else remove cached rates */
                if (storageItemsRateLength > 0 && storageItemsRateLength < cacheItemsRatesLimit) {
                    updatedAssets = {
                        ...ratesFromStorage,
                        ...ratesAndProductid
                    };
                } else {
                    updatedAssets = ratesAndProductid;
                }
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES, JSON.stringify(updatedAssets));
            }
            dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: updatedAssets });
            // dispatch({
            //     type: SET_RATES_LOADING_FLAG,
            //     isRatesLoading: false
            // });
        } catch (error) {
            logError(error, false, 'updateRatesForProducts');
        }
    };

    const updateRatesForProductsPDP = (ratesObj = {}, error) => {
        if (error) {
            pdpDispatch({ type: PDP_GET_RATES, rates: {} });
        } else {
            pdpDispatch({ type: PDP_GET_RATES, rates: ratesObj?.items });
        }
        pdpDispatch({
            type: SET_RATES_LOADING_FLAG,
            isRatesLoading: false
        });
    };

    const getProductPrices = (items = [], pageType) => {
        switch (pageType) {
            case VARIABLE_CONFIG.PAGE_TYPE.PLP: {
                getProductPricesPLP(items);
                break;
            }
            case VARIABLE_CONFIG.PAGE_TYPE.SEARCH_PAGE: {
                getProductPricesSRP(items);
                break;
            }
            case VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE:
            case VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE: {
                getProductPricesHomePage(items);
                break;
            }
            case VARIABLE_CONFIG.PAGE_TYPE.ALTERNATE_INVENTORY: {
                getProductPricesAlternateInventory(items);
                break;
            }
            case VARIABLE_CONFIG.PAGE_TYPE.PDP: {
                getProductPricesPDP(items);
                break;
            }
            case VARIABLE_CONFIG.PAGE_TYPE.P2P_WISHLIST_ITEMS: {
                getProductPricesP2PWishlistItems(items);
                break;
            }
            default:
                dispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: false
                });
                break;
        }
    };

    const setRatesFromStorage = async () => {
        let ratesFromStorage = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
        dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: ratesFromStorage });
        dispatch({
            type: SET_RATES_LOADING_FLAG,
            isRatesLoading: false
        });
    };

    // For P2P WishlistItems page
    const getProductPricesP2PWishlistItems = async (items = []) => {
        // here items will contain sku's directly
        try {
            const arrgroup = splitArrayByIndex(items);
            arrgroup.forEach(productsArr => {
                const { ownedPcs, ownedPcsItemsMap } = getOwnedPcArrayP2P(productsArr);
                if (ownedPcs?.length > 0) {
                    getProductPricesByOwnedPcs(ownedPcs, ownedPcsItemsMap);
                } else {
                    setRatesFromStorage();
                }
            });
        } catch (error) {
            logError(error, false, 'getProductPricesP2PWishlistItems', [items]);
        }
    };

    const getProductPricesPLP = async (items = []) => {
        try {
            const arrgroup = splitArrayByIndex(items);
            for (const productsArr of arrgroup) {
                const { ownedPcs, ownedPcsItemsMap } = getOwnedPcArrayPLP(productsArr);
                if (ownedPcs?.length > 0) {
                    await getProductPricesByOwnedPcs(ownedPcs, ownedPcsItemsMap);
                } else {
                    await setRatesFromStorage();
                }
            }
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        } catch (error) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'getProductPricesPLP', [items]);
        }
    };
    const getOverrideRatesPc = () => {
        const locationPCObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)) || {};
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        const { enableownedpcrates } = ENV_CONFIG.RATE_CONFIGS || {};

        const isNewRates = JSON.parse(enableownedpcrates || true);
        let pc = 0;
        if (overridePC?.pc) {
            pc = overridePC?.pc;
        } else if (!isNewRates) {
            pc = locationPCObj?.pc;
        }
        return pc;
    };
    const getProductPricesSRP = async (items = []) => {
        try {
            const arrgroup = splitArrayByIndex(items);
            for (const productsArr of arrgroup) {
                const { ownedPcs, ownedPcsItemsMap } = getOwnedPcArraySRP(productsArr);
                if (ownedPcs?.length > 0) {
                    await getProductPricesByOwnedPcs(ownedPcs, ownedPcsItemsMap);
                } else {
                    await setRatesFromStorage();
                }
            }
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        } catch (error) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'getProductPricesSRP', [items]);
        }
    };

    const getProductPricesHomePage = async (items = []) => {
        try {
            const arrgroup = splitArrayByIndex(items);
            for (const productsArr of arrgroup) {
                const { ownedPcs, ownedPcsItemsMap } = getOwnedPcArrayHomePage(productsArr);
                if (ownedPcs?.length > 0) {
                    await getProductPricesByOwnedPcs(ownedPcs, ownedPcsItemsMap);
                } else {
                    await setRatesFromStorage();
                }
            }
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        } catch (error) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'getProductPricesHomePage', [items]);
        }
    };

    const getProductPricesAlternateInventory = async (items = []) => {
        try {
            const arrgroup = splitArrayByIndex(items);
            for (const productsArr of arrgroup) {
                const { ownedPcs, ownedPcsItemsMap } = getOwnedPcArrayAlternateItems(productsArr);
                if (ownedPcs?.length > 0) {
                    await getProductPricesByOwnedPcs(ownedPcs, ownedPcsItemsMap);
                } else {
                    await setRatesFromStorage();
                }
            }
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        } catch (error) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'getProductPricesAlternateInventory', [items]);
        }
    };

    const getOwnedPcArrayPDP = product => {
        try {
            const ownedPcs = [];
            const unavailablePcs = [];
            const productArr = [];
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];
            const nearbyPc = findNearestPcPDP(product, nearbyPCs);
            if (nearbyPc !== -1) {
                // will be used later for unavailable items
                ownedPcs.push({ pc: nearbyPc, productId: product?.catclass });
            } else {
                unavailablePcs.push({ nearbyPc, sku: product?.catclass });
            }
            productArr.push(product?.catclass);
            return { ownedPcs, unavailablePcs, products: productArr };
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayPDP', [product]);
        }
    };

    const getProductPricesPDP = async (items = []) => {
        try {
            const { ownedPcs } = getOwnedPcArrayPDP(items[0]);
            await getProductPricesByOwnedPcsPDP(ownedPcs);
            pdpDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        } catch (error) {
            pdpDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'getProductPricesPDP', [items]);
        }
    };

    const fetchRatesForProducts = async (
        accountNumber,
        jobsiteNumber,
        productArray
    ) => {
        const arrgroup = splitArrayByIndex(productArray);
    
        // Make all API calls in paralle    l and handle rejections gracefully
        const responses = await Promise.allSettled(
            arrgroup.map(productsArr =>
                getRatesByOwnedPc(accountNumber, jobsiteNumber, productsArr)
            )
        );
    
        // Filter only fulfilled responses
        const successfulResponses = responses
            .filter(response => response.status === "fulfilled")
            .map(response => response.value);
    
        // Merge all valid API responses
        return successfulResponses.reduce(
            (allItems, { data }) => [...allItems, ...data?.data?.items],
            []
        );
    };

    const getAccessoriesPrices = async (items = []) => {
        try {
            dispatch({ type: SET_ACCESSORIES_RATE_LOADING, isAccessoriesRatesLoading: true });
            const arrgroup = splitArrayByIndex(items);
            const productPriceList = [];
            for (const productsArr of arrgroup) {
                const ownedPcs  = await getOwnedPcArrayAccessories(productsArr);
                const { priceDetails, error } = await getProductPricesByOwnedPcsForAccessories(ownedPcs);
                if(!error && priceDetails?.length) {
                    productPriceList.push(...priceDetails);
                }
            }
            const rateList = {};
            productPriceList?.forEach(item=>{
                rateList[item?.productId] = {...item};
            });
            dispatch({ type: SET_ACCESSORIES_RATE_LOADING, isAccessoriesRatesLoading: false });
            dispatch({ type: SET_ACCESSORIES_RATE, accessoriesRates: rateList });
            return productPriceList;
        } catch (error) {
            dispatch({ type: SET_ACCESSORIES_RATE_LOADING, isAccessoriesRatesLoading: false });
            logError(error, false, 'getAccessoriesPrices', [items]);
        }
    };

    const getOwnedPcArrayAccessories = async (skuList) => {
        try {
            const ownedPcs = [];
            const { productDetails } = await getProductList({
                productListQuery,
                skuList,
                handleATPCart
            });
            const nearbyPCs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];
            const storePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}');
            productDetails?.forEach(item => {
                const overridePC = getOverrideRatesPc();
                const nearbyPc =  overridePC || findNearestPcAccessories(item, nearbyPCs);
                if (nearbyPc !== -1) {
                    ownedPcs.push({ pc: nearbyPc, productId: item?.catclass });
                } else {
                    ownedPcs.push({pc: storePC, productId: item?.catclass });
                }
            });
            return ownedPcs;
        } catch (error) {
            logError(error, false, 'getOwnedPcArrayAccessories', [products]);
            return [];
        }
    }

    const findNearestPcAccessories = (item, nearbyPCs = []) => {
        try {
            const pcAvailability = getInventoryPcs(item?.ec_pc_inventory);
            return findNearestPc(pcAvailability, nearbyPCs);
        } catch (error) {
            logError(error, false, 'findNearestPcAccessories', [item, nearbyPCs]);
        }
    };

    const getProductPricesByOwnedPcsForAccessories = async (ownedPcs = []) => {
        try {
            const { customerNumber, jobSite } = getRatesApiCustJobsitePayload();
            if (ownedPcs?.length > 0) {
                const { data, error } = await getRatesByOwnedPc(customerNumber, jobSite, ownedPcs);
                return { priceDetails: data?.data?.items, error }
            }
            return { priceDetails: [] };
        } catch (error) {
            logError(error, false, 'getProductPricesByOwnedPcs', [ownedPcs]);
        }
    }

    return {
        getProductPrices,
        getOverrideRatesPc,
        findNearestPc,
        fetchRatesForProducts,
        getAccessoriesPrices
    };
};

/**
 * rates=>plp,srp,home,pdp=>getOwnedPcArrayPLP(products)=>[{pc,sku},{pc,sku}]
 * [{pc,sku},{pc,sku}] //always but overridepc
 * if credit => jobsite,customerNumber, isCreditNewAddress, jobsite:'', customerNumber:acc
 * overridepc=> existing rates api
 * locationcall=>address,autosuggest, placedetails,geocode, avs.=>useComputeLocation => Nikhil=> SWITCH(USERTYPE)//C
 *
 *
 */
