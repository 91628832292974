import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import Price from '../../../../aem-core-components/components/Price/price';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import makeUrl from '../../../../aem-core-components/utils/makeUrl';
import { transparentPlaceholder } from '../../../../aem-core-components/utils/transparentPlaceholder';
import { usePdpState } from '../../../../contexts/pdp/pdpContext';
import DefaultViewNoImage from '../../../global/atoms/defaultViewNoImage/defaultViewNoImage';
import MaxQtyErrorComponent from '../../../global/atoms/maxQtyErrorComponent';
import { pdpDataLocators } from '../../../pdp/dataLocators';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import classes from './rentalreviewlistview.css';
import useCartEstimate from '../../../App/hooks/useCartEstimate';
import { AUTHORITY_TYPE } from '../../../global/constants';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';

const imageWidth = 56;
const imageHeight = 56;
const RentalReviewListView = props => {
    const { item, orderEstimates, isFromPDP, pdpQty, localItemObj, tabIndexValue=0 } = props;
    const intl = useIntl();
    const nativeLazyLoad = 'loading' in window.HTMLImageElement.prototype;
    const { product = {}, prices = {} } = item || {};
    const { thumbnail = '', name = '' } = product;
    const { price = {}, row_total = {} } = prices;
    const [{ consumables, homeAssetsRates }] = useCartState();
    const authorityType = useCheckAuthorityType();
    const [{ userProfile }] = useUserContext();
    const [{ rates }] = usePdpState();
    const { orderSummaryRentals } = consumables;
    const [showAccessoriesAddons, setShowAccessoriesAddons] = useState(false);
    const [itemRate, setItemRate] = useState(localItemObj?.price);
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const [brokenImage, setBrokenImage] = useState(false);
    let { catsku } = document.querySelector('.block--pdp-hero')?.dataset || {};
    const cartitem = orderEstimates?.itemizedCharges?.products.find(product => {
        return `${product?.catId}${product?.classId}` === item?.product?.sku;
    });

    useEffect(() => {
        setShowAccessoriesAddons(true);
    }, [orderSummaryRentals]);

    useEffect(() => {
        setItemRate(localItemObj?.price);
    }, [localItemObj?.price]);

    useEffect(() => {
        // below is to identify rates for PDP page else global
        if (!localItemObj?.price || localItemObj?.price === '-') {
            if (catsku && Array.isArray(rates)) {
                let rItem = rates?.find(rateItem => {
                    return rateItem?.productId == catsku;
                });
                setItemRate(
                    rItem?.rates?.suggestedRates?.daily ||
                        price?.value ||
                        (isEstimatesWithOwnedPc() ? row_total?.value : cartitem?.rentalCost) ||
                        row_total?.value
                );
            } else {
                setItemRate(
                    homeAssetsRates[item?.product?.sku]?.daily ||
                        (isEstimatesWithOwnedPc() ? row_total?.value : cartitem?.rentalCost) ||
                        row_total?.value
                );
            }
        }
    }, [item]);

    const productImage = useMemo(() => {
        const src =
            thumbnail && thumbnail.url
                ? makeUrl(thumbnail.url, { type: 'image-product', width: imageWidth, height: imageHeight })
                : localItemObj?.img;
        return src && !brokenImage ? (
            nativeLazyLoad ? (
                <img
                    height={imageHeight}
                    width={imageWidth}
                    alt={name || localItemObj?.title}
                    className={classes.image}
                    loading="lazy"
                    placeholder={transparentPlaceholder}
                    src={src}
                    onError={() => {
                        setBrokenImage(true);
                    }}
                />
            ) : (
                <img
                    height={imageHeight}
                    width={imageWidth}
                    alt={name || localItemObj?.title}
                    className={`${classes.image} lazyload`}
                    placeholder={transparentPlaceholder}
                    loading="lazy"
                    src={src}
                    onError={() => {
                        setBrokenImage(true);
                    }}
                />
            )
        ) : (
            <DefaultViewNoImage cardType="minicart"></DefaultViewNoImage>
        );
    });

    const accessoriesAndAddonsView = (selectedArray, consumableType) => {
        return (
            <>
                {selectedArray.map((rentalItem, index) => {
                    return (
                        <div key={`${rentalItem?.rentalClass}${index}`} className={classes.accessoriesWrapper}>
                            <div
                                className={classes.accessoryNameContainer}
                                data-testid={checkoutDatalocator.checkout_accordion_rentals_name}>
                                <small className={classes.accessoryName} tabIndex={0}>
                                    {rentalItem.name}
                                </small>
                            </div>
                            <div className={classes.accessoryFooterSection}>
                                <div
                                    className={classes.accessoryQtyContainer}
                                    data-testid={checkoutDatalocator.checkout_accordion_rentals_qty}>
                                    <small className={classes.accessoryQty} tabIndex={0}>
                                        {intl.formatMessage({ id: 'checkout-addOns:product-card-qty' })}{' '}
                                        {rentalItem.qty}
                                    </small>
                                </div>
                                <div
                                    className={classes.accessoryPriceContainer}
                                    data-testid={checkoutDatalocator.checkout_accordion_rentals_price}>
                                    <small className={classes.accessoryPriceText} tabIndex={0}>
                                        {consumableType === 'rentals'
                                            ? intl.formatMessage({ id: 'checkout:rental-equipments-accessories-type' })
                                            : intl.formatMessage({ id: 'checkout:rental-equipments-addons-type' })}
                                    </small>
                                    <span className={classes.accessoryPrice} tabIndex={0}>
                                        <Price
                                            currencyCode="USD"
                                            included={rentalItem?.isForced && rentalItem?.price === 0}
                                            value={parseFloat(
                                                rentalItem?.qty *
                                                    (consumableType === 'rentals'
                                                        ? consumables?.rentablesWithPrice?.[rentalItem?.itemSku]
                                                        : rentalItem.price)
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const accessoriesAndAddons = () => {
        const rentals = [];
        const addOns = [];
        let selectedConsumablesLength = Object.keys(orderSummaryRentals).length;
        if (selectedConsumablesLength > 0 && orderSummaryRentals[item?.product?.sku]) {
            let consumablesObj = orderSummaryRentals[item?.product?.sku];
            if (consumablesObj?.['rentals']) {
                Object.keys(consumablesObj?.['rentals'])?.forEach(sku => {
                    rentals.push({ ...(consumablesObj?.['rentals']?.[sku] || {}), itemSku: sku });
                });
            }
            if (consumablesObj?.['addons']) {
                addOns.push(...Object.values(consumablesObj?.['addons']));
            }
        }
        return (
            <>
                {rentals.length > 0 && accessoriesAndAddonsView(rentals, 'rentals')}
                {addOns.length > 0 && accessoriesAndAddonsView(addOns, 'addOns')}
            </>
        );
    };

    return (
        //will remove this condition logic in future sprint ecom story
        <div className={`${classes.root} ${authorityType !== AUTHORITY_TYPE.P2P ? classes.ecomUser : ''}`}>
            <div
                className={classes.eqipmentIcon}
                data-testid={
                    isFromPDP
                        ? pdpDataLocators.added_to_cart_product_image
                        : checkoutDatalocator.checkout_product_img_testid
                }
                tabIndex={tabIndexValue}
                aria-label={name || localItemObj?.title + ' image'}>
                {productImage}
            </div>
            <div className={classes.equipmentDescriptions}>
                <div
                    className={classes.equipmentName}
                    data-testid={
                        isFromPDP
                            ? pdpDataLocators.added_to_cart_product_title
                            : checkoutDatalocator.checkout_product_title_testid
                    }
                    tabIndex={tabIndexValue}>
                    {name || localItemObj?.title}
                </div>
                <div className={classes.equipmentText}>
                    <div
                        className={classes.equipmentQuantity}
                        data-testid={
                            isFromPDP
                                ? pdpDataLocators.added_to_cart_product_qty
                                : checkoutDatalocator.checkout_qty_label_testid
                        }
                        tabIndex={tabIndexValue}>
                        Qty: {isFromPDP ? pdpQty : item?.quantity}
                    </div>
                    <div
                        className={classes.equipmentPrice}
                        data-testid={
                            isFromPDP
                                ? pdpDataLocators.added_to_cart_product_price
                                : checkoutDatalocator.checkout_price_label_testid
                        }
                        tabIndex={tabIndexValue}>
                        {itemRate ? (
                            <>
                                <div className={classes.eqipmentRate}>
                                    <Price currencyCode={row_total?.currency} value={itemRate} />{' '}
                                </div>
                                <div className={!isFromPDP ? `${classes.eqipmentRate}` : `${classes.eqipmentRatePDP}`}>
                                    {'/day'}
                                </div>
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
                {showAccessoriesAddons && accessoriesAndAddons()}
                {!isFromPDP && (
                    <MaxQtyErrorComponent
                        skipQtyObj={userProfile?.skip_qty}
                        sku={item?.product?.sku}
                        isMergeCart={true}
                    />
                )}
            </div>
        </div>
    );
};

// RentalReviewListView.propTypes = {
//     item: shape({
//         quantity: number.isRequired,
//         prices: shape({
//             price: shape({
//                 value: number.isRequired,
//                 currency: string.isRequired
//             }).isRequired,
//             row_total: shape({
//                 value: number.isRequired,
//                 currency: string.isRequired
//             }).isRequired
//         }).isRequired,
//         product: shape({
//             name: string.isRequired,
//             image: object
//         })
//     })
// };

export default RentalReviewListView;
