import React, { useEffect, useState } from "react";
import { bool, object, string } from "prop-types";
import { ListView as StorybookListView } from "@sunbeltrentals/sbr-global-components";
import { logError } from "../../../global/utils/logger";
import { useCartState } from "../../../../contexts/cart";
import RequiredAccessoriesAddonsInfoModal from "../requiredAccessoriesAddonsInfoModal/RequiredAccessoriesAddonsInfoModal";
import RentalItem from "./components/RentalItem";
import RentalDetail from "./components/RentalDetail";
import "./RequiredAccessoriesAddonsDetails.scss";

const RequiredAccessoriesAddonsDetails = (props) => {
  const {
    isModalView = false,
    title = "",
    forcedItemsDetails = {},
  } = props || {};
  const [{ accessoriesRates, isAccessoriesRatesLoading }] = useCartState();

  const [forceItems, setForceItems] = useState([]);
  useEffect(() => {
    try {
      const result = [];
      if (forcedItemsDetails && Object.keys(forcedItemsDetails)?.length) {
        const addOns = forcedItemsDetails?.addons;
        const accessories = forcedItemsDetails?.rentals;
        addOns?.forEach((item) => {
          result.push({
            ...item,
            minQuantity: 1,
          });
        });
        accessories?.forEach((item) => {
          result.push({
            ...item,
            sellingPrice: parseFloat(
              accessoriesRates[item?.sku]?.rates?.suggestedRates?.daily ||
                item?.sellingPrice ||
                0
            ),
            minQuantity: 1,
          });
        });
        setForceItems(result);
      }
    } catch (error) {
      logError(error, false, "setting up forced details");
    }
  }, [forcedItemsDetails, accessoriesRates]);

  const calculateTotal = (items) => {
    return items?.reduce(
      (acc, item) => {
        acc.totalQty += item?.minQuantity;
        acc.totalPrice += item?.sellingPrice * item?.minQuantity;
        return acc;
      },
      { totalQty: 0, totalPrice: 0 }
    );
  };

  const { totalQty, totalPrice } = calculateTotal(forceItems);

  const getTotalPriceAndQuantitySection = () => {
    return (
        !isModalView && forceItems?.length > 1 && (
            <div className="rentals_details_container rentals_details">
              <RentalDetail label="items" value={totalQty} type="qty" />
              {isAccessoriesRatesLoading ? (
                <div className="accessories-rates-loading shimmer"></div>
              ) : (
                <RentalDetail
                  label="Total per day"
                  value={totalPrice}
                  type="price"
                />
              )}
            </div>
        )
    );
  }

  const getConsumablesList = () => {
    return (
        <StorybookListView
        columns={1}
        itemsPerColumn={2}
        data={forceItems?.map((item) => (
          <RentalItem key={item?.sku} {...item} isModalView={isModalView} />
        ))}
        customClassColumn={"rental_details_columns"}
        shouldHideLastLine={isModalView ? false : forceItems?.length < 2}
      />
    );
  }

  return (
    <>
      <div className="card_rentals">
        <h5 className="card_rentals_title">{title}</h5>
        {!isModalView && <RequiredAccessoriesAddonsInfoModal title={title} isModalView={isModalView} />}
      </div>
      {getConsumablesList()}
      {getTotalPriceAndQuantitySection()}
    </>
  );
};

RequiredAccessoriesAddonsDetails.propTypes = {
  isModalView: bool,
  forcedItemsDetails: object,
  title: string,
};

export default React.memo(RequiredAccessoriesAddonsDetails);
