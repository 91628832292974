import { logError } from '../../../components/global/utils/logger';
import { ERROR_MSG } from '../../../constants/errorMappingCodes';
/**
 * Retrieves the consumable products of a product
 * @param {Object} payload a parameters object with the following structure:
 *      consumablesGQL - the query object to execute
 *      dispatch - the dispatch callback for the consumables context
 */
export const getConsumablesAction = async payload => {
    const { consumablesGQL, payloadForConsumables } = payload;
    const { pcId = '', account = '', jobNumber = '', items = [] } = payloadForConsumables;
    try {
        const { data, errors } = await consumablesGQL({
            variables: {
                pcId,
                account,
                jobNumber,
                items
            },
            fetchPolicy: 'network-only'
        });
        if (errors) {
            logError(errors?.message || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'getConsumablesAction', [payload]);
        }
        if (parseInt(data?.getConsumables?.status) >= 500) {
            logError(
                data?.getConsumables?.message || 'Something went wrong in consumables API',
                true,
                'getConsumablesAction',
                [payload]
            );
        } else {
            return data?.getConsumables?.suggestedItems;
        }
    } catch (error) {
        logError(error || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'getConsumablesAction', [payload]);
    }
};

/**
 * Add or update consumables 
 * @param {Object} payload a parameters object with the following structure:
 *      updateConsumablesGQL - the mutation to execute
 *      cartId
 *      consumables - stringified value of consumables to add/update
 */
export const updateConsumablesAction = async ({ updateConsumablesGQL, cartId, consumables }) => {
    try {
        const { data, errors } = await updateConsumablesGQL({
            variables: {
                cartId,
                accessories: consumables
            },
            fetchPolicy: 'network-only'
        });
        if (errors) {
            logError(errors?.message || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'updateConsumablesAction', [cartId]);
        }
        return data;
    } catch (error) {
        logError(error || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'updateConsumablesAction', [cartId]);
        return { error };
    }
};