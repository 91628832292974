export const getCookie = (name) =>{
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null; // Return null if the cookie is not found
}

export const USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit',
    PUNCHOUT: 'punchout'
};

export const getUserType =() =>{
    return sessionStorage.getItem('userType');
}


const addGrayBgClass = (el) => {
    if (el.className.indexOf('tracker_background') === -1) {
        el.className = `${el.className} tracker_background`;
    }
};
  
const checkBgClass = () => {
    const rentalTracker = document.querySelector('.rental-tracker');
    if (rentalTracker) {
        addGrayBgClass(document?.body);
    }
};
  
if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    checkBgClass();
} else {
    document.addEventListener('DOMContentLoaded', checkBgClass);
}
  
