import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useFilterState } from '../components/cap';
import { useCartState } from '../contexts/cart';
import useCartEstimate from '../components/App/hooks/useCartEstimate';
import useCheckLocationEmpty from './useCheckLocationEmpty';
import { useCheckUser } from './useCheckUser';
import { getConsumablesAction, updateConsumablesAction } from '../aem-core-components/actions/consumables';
import isObjectEmpty from '../aem-core-components/utils/isObjectEmpty';
import isObjectWithKeys from '../aem-core-components/utils/isObjectWithKeys';
import { getRentablesPrice } from '../components/checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { formatjobsiteZip, generateSKU, extractCatAndClass } from '../components/global/utils/commonUtils';
import { removeZeroPriceItems, getAvailableConsumablesConfig } from '../components/cart/utils/helper';
import { logError } from '../components/global/utils/logger';
import { getTotalFromConsumables } from '../components/checkoutv2/utils/checkoutUtils';
import { FORCE_ITEM } from '../components/checkoutv2/constants';
import { STOCK_CLASS_FUEL, STOCK_CLASS_MISCELLANEOUS } from '../components/molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import { USER_TYPE } from '../constants/userDetailsConstants';
import {
    SET_CONSUMABLES,
    SET_CONSUMABLES_SELECTED_ITEMS,
    SET_IS_CART_LOADING,
    SET_IS_ESTIMATE_UPDATE_REQUIRED,
    SET_SAVED_AVAILABLE_QUANTITY,
    UPDATE_CONSUMABLES
} from '../aem-core-components/actions/constants';
import mutation_consumables from '../aem-core-components/queries/mutation_consumables.graphql';
import MUTATION_UPDATE_CONSUMABLES from '../aem-core-components/queries/mutation_update_consumables.graphql';
import MUTATION_BULK_UPDATE_CONSUMABLES from '../aem-core-components/queries/mutation_bulk_update_consumables.graphql';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { SUCCESS } from '../components/global/constants';

const useConsumables = () => {
    const [consumablesGQL] = useMutation(mutation_consumables);
    const [updateConsumablesGQL] = useMutation(MUTATION_UPDATE_CONSUMABLES);
    const [bulkUpdateConsumablesGQL] = useMutation(MUTATION_BULK_UPDATE_CONSUMABLES);
    const [{ getStartDate, getEndDate }] = useCartEstimate();
    const [{ userProfile }] = useUserContext();
    const [{ cartId, cart, isCreditNewAddress, userAccount, howToGetYourOrderDetails, consumables }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }] = useFilterState();
    const userType = useCheckUser();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();
    const cartItems = cart?.items || [];

    const { selectedStoreDetails } = howToGetYourOrderDetails;
    const handlePayloadPC = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.locationPC;
        } else {
            return viewCart?.pc;
        }
    };
    const handlePayloadAccount = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return userAccount?.accountNumber;
        } else {
            return '';
        }
    };
    const handlePayloadJobNumber = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.selectedProjectJobId;
        } else {
            return '';
        }
    };

    const setRentablesPrice = async (items, pc) => {
        try {
            let zipCode = viewCart?.jobSiteZip || projectDetails?.selectedProjectZip || '';

            const drivingDistance = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP) || '{}'
            )?.drivingDistanceFromJobsite;

            const browseLocationOBJ = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
            )?.distance;
            let payload = {
                cartId: String(cart?.id || cartId) || '',
                pc: pc || handlePayloadPC(),
                jobSiteAddr1: viewCart?.location || projectDetails?.projectAddress1 || '',
                jobSiteAddr2: viewCart?.jobSiteAddr2 || projectDetails?.projectAddress2 || '',
                jobSiteCity: viewCart?.jobSiteCity || projectDetails?.selectedProjectCity || '',
                jobSiteState: viewCart?.jobSiteState || projectDetails?.selectedProjectState || '',
                jobSiteZip: formatjobsiteZip(zipCode),
                dlSt: userType === USER_TYPE.CASH ? userProfile?.dlState : '',
                dlNumber: userType === USER_TYPE.CASH ? userProfile?.dlNumber : '',
                startDateAndTime: startDate ? getStartDate() : '',
                endDateAndTime: endDate ? getEndDate() : '',
                lat: String(viewCart?.lat) || String(projectDetails?.selectedProjectLatititude) || '',
                long: String(viewCart?.long) || String(projectDetails?.selectedProjectLongitude) || '',
                distance: viewCart?.isInStorePickup ? 0 : drivingDistance || browseLocationOBJ || -1,
                timeType: viewCart?.isInStorePickup ? 'Pickup' : 'Delivery',
                lastResort: selectedStoreDetails?.isLastResortBranch ? true : false,
                fulfillment: selectedStoreDetails?.fulfillmentPercent || '',
                pcLat: String(viewCart?.pcLat) || '',
                pcLong: String(viewCart?.pcLong) || '',
                projectId: String(projectDetails?.selectedProjectJobId) || '',
                isDeliveryEstimate: viewCart?.isInStorePickup ? false : true
            };
            let payloadCredit = {
                ...payload,
                account: userAccount?.accountNumber
            };
            let rentablesWithPriceMap = {};
            if (items?.length > 0) {
                const mainCartItems = cartItems?.map(prodObj => {
                    const { sku = '' } = prodObj?.product;
                    const quantity = prodObj?.quantity;
                    const { catId, classId } = extractCatAndClass(sku);
                    return {
                        catId,
                        classId,
                        quantity
                    };
                });

                let payloadWithRentables =
                    userType === USER_TYPE.CREDIT
                        ? { ...payloadCredit, equipment: mainCartItems, salesEquipments: [] }
                        : { ...payload, equipment: mainCartItems, salesEquipments: [] };
                for (let i in items) {
                    for (let j in items[i].rentables) {
                        payloadWithRentables = {
                            ...payloadWithRentables,
                            equipment: [
                                ...payloadWithRentables.equipment,
                                {
                                    catId: ('000' + items[i].rentables[j].rentalCat).slice(-3),
                                    classId: ('0000' + items[i].rentables[j].rentalClass).slice(-4),
                                    quantity: 1
                                }
                            ]
                        };
                    }

                    items[i].consumables.forEach(consumable => {
                        consumable?.specGroups.forEach(specGroup => {
                            specGroup?.items
                            .forEach(item => {
                                if (item?.forceItem === FORCE_ITEM.YES) {
                                    payloadWithRentables = {
                                        ...payloadWithRentables,
                                        salesEquipments: [
                                            ...payloadWithRentables.salesEquipments,
                                            {
                                                itemNumber: item?.itemNumber,
                                                quantity: parseInt(item?.minQuantity),
                                                unitPrice: parseFloat(item?.sellingPrice),
                                                stockClass: item?.stockClass
                                            }
                                        ]
                                    };
                                }
                            });
                        });
                    });
                }

                if (payloadWithRentables?.equipment?.length > 0 || payloadWithRentables?.salesEquipments?.length > 0) {
                    const { data, error } = await getRentablesPrice(payloadWithRentables);
                    if (data) {
                        for (let k in data?.estimate?.itemizedCharges?.products) {
                            rentablesWithPriceMap[
                                generateSKU(
                                    data.estimate.itemizedCharges.products[k].catId,
                                    data.estimate.itemizedCharges.products[k].classId
                                )
                            ] = data?.estimate?.itemizedCharges?.products[k]?.unitRentalCost;
                        }
                    }
                    data?.estimate?.itemizedCharges?.salesItems.forEach(item => {
                        const key = item?.itemNumber + item?.stockClass;
                        rentablesWithPriceMap[key] = item?.unitPrice;
                    });
                }
            }
            return rentablesWithPriceMap;
        } catch (error) {
            logError(error, false, 'setRentablesPrice');
        }
    };

    const excludeForcedFuelItems = (response) => {
        return response?.map((suggestedItem) => ({
            ...suggestedItem,
            rentables: suggestedItem?.rentables,
            consumables: suggestedItem?.consumables
                .map((consumable) => ({
                    ...consumable,
                    specGroups: consumable?.specGroups
                        .map((specGroup) => ({
                            ...specGroup,
                            items: specGroup?.items?.filter(
                                (item) =>
                                    !(
                                        item?.forceItem === FORCE_ITEM.YES &&
                                        item?.stockClass?.toUpperCase() === STOCK_CLASS_FUEL &&
                                        item?.lineItemType?.toUpperCase() === STOCK_CLASS_MISCELLANEOUS
                                    )
                            ),
                        }))
                        .filter((specGroup) => specGroup?.items?.length > 0), // Remove empty specGroups
                }))
                .filter((consumable) => consumable?.specGroups?.length > 0), // Remove empty consumables
        }));
    }
    

    const getConsumables = async (pc = '', cartItems = cart?.availableCartItems) => {
        try {
            let data = consumables?.dataWithoutPrice;
            if (!data) {
                const items = cartItems?.map(item => item.product.sku);
                let payloadForConsumables = {
                    pcId: pc || handlePayloadPC(),
                    account: handlePayloadAccount(),
                    jobNumber: handlePayloadJobNumber(),
                    items
                };
                const response = await getConsumablesAction({
                    consumablesGQL,
                    payloadForConsumables
                });
                data = await excludeForcedFuelItems(response);
            }
            const estimates = await setRentablesPrice(data, pc);
            dispatch({
                type: SET_CONSUMABLES,
                consumables: removeZeroPriceItems(data, estimates),
            });
            return { consumablesData: data, rentablesPriceObj: estimates };
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getConsumables', [pc]);
            } else {
                logError(error, false, 'getConsumables', [pc]);
            }
        }
    };

    const formatAccessories = accessories => {
        try {
            const accessoriesObj = {};
            accessories?.forEach(accessory => {
                accessoriesObj[accessory?.sku] = {
                    name: accessory?.name,
                    qty: 1,
                    itemObj: {
                        forceItem: accessory?.forceItem
                    }
                };
            });
            return accessoriesObj;
        } catch (error) {
            logError(error, false, 'formatAccessories');
            return {};
        }
    };

    const formatAddons = addons => {
        try {
            const addonObj = {};
            addons?.forEach(addon => {
                const key = `${addon?.itemNumber}${addon?.stockClass}${addon?.oemStockNumber}`;
                addonObj[key] = {
                    name: addon?.name,
                    price: parseFloat(addon?.sellingPrice),
                    qty: 1,
                    itemObj: {
                        forceItem: addon?.forceItem,
                        oemStockNumber: addon?.oemStockNumber,
                        itemNumber: addon?.itemNumber,
                        lineItemType: addon?.lineItemType,
                        stockClass: addon?.stockClass
                    }
                };
            });
            return addonObj;
        } catch (error) {
            logError(error, false, 'formatAddons');
            return {};
        }
    };

    const getConsumablesForAddToCart = (forcedItems, catsku) => {
        try {
            const forcedAccessories = forcedItems?.rentals;
            const forcedAddOn = forcedItems?.addons;
            if (!forcedAccessories?.length && !forcedAddOn?.length) {
                return '';
            }
            const { catId, classId } = extractCatAndClass(catsku);
            return JSON.stringify([
                {
                    cat: catId,
                    class: classId,
                    rentals: formatAccessories(forcedAccessories),
                    addons: formatAddons(forcedAddOn)
                }
            ]);
        } catch (error) {
            logError(error, false, 'getConsumablesForAddToCart');
        }
    };

    const updateAvailableQty = availableConsumableData => {
        dispatch({
            type: SET_SAVED_AVAILABLE_QUANTITY,
            key: 'savedAvailableQuantity',
            value: availableConsumableData
        });
        dispatch({
            type: SET_SAVED_AVAILABLE_QUANTITY,
            key: 'availableQuantity',
            value: availableConsumableData
        });
    };

    const getSelectedConsumablesAvailability = async (items, pc, enableLoader = false, stringifiedConsumables = '') => {
        try {
            enableLoader && dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            const selectedConsumables = isObjectEmpty(consumables?.selected)
                ? parseConsumablesData(stringifiedConsumables)
                : consumables?.selected;
            const updatedSelectedConsumables = cloneDeep(selectedConsumables);
            let consumablesData, rentablesPriceObj;
            if (items?.length && isRentalDetailsAvailable()) {
                ({ consumablesData, rentablesPriceObj } = await getConsumables(pc, items));
                const availableConsumableData = getAvailableConsumablesConfig(consumablesData);
                updateSelectedConsumableData(updatedSelectedConsumables, consumablesData, availableConsumableData);
                updateAvailableQty(availableConsumableData);
                await bulkUpdateConsumables(updatedSelectedConsumables);
            }
            dispatch({
                type: SET_CONSUMABLES_SELECTED_ITEMS,
                selectedItems: updatedSelectedConsumables
            });
            enableLoader && dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return { updatedSelectedConsumables, rentablesPriceObj };
        } catch (error) {
            logError(error, false, 'getSelectedConsumablesAvailability', [items]);
            enableLoader && dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return {};
        }
    };

    const updateSelectedConsumableData = (selectedConsumables, consumablesData, availableConsumableData) => {
        try {
            if (!selectedConsumables || isObjectEmpty(selectedConsumables)) return {};
            const lookup = createConsumablesLookup(consumablesData);

            Object.keys(selectedConsumables).forEach(key => {
                const addons = selectedConsumables?.[key]?.addons || {};
                const accessories = selectedConsumables?.[key]?.rentals || {};
                updateSelectedDetails(addons, lookup[key], true, availableConsumableData?.remainingAddOns || {});
                updateSelectedDetails(
                    accessories,
                    lookup[key],
                    false,
                    availableConsumableData?.remainingAccessories || {}
                );
            });
        } catch (error) {
            logError(error, false, 'updateSelectedConsumableData', [selectedConsumables, consumablesData]);
            return {};
        }
    };

    const createConsumablesLookup = consumablesData => {
        try {
            const lookup = {};
            consumablesData?.forEach(consumable => {
                const productSku = generateSKU(consumable?.cat, consumable?.class);
                lookup[productSku] = {};

                consumable?.rentables?.forEach(accessory => {
                    const accessorySku = accessory?.product?.sku;
                    lookup[productSku][accessorySku] = {
                        sequence: accessory?.sequence,
                        minRate: accessory?.minRate,
                        dayRate: accessory?.dayRate,
                        wkRate: accessory?.wkRate,
                        moRate: accessory?.moRate,
                        IsSerialized: accessory?.IsSerialized,
                        category_name: accessory?.product?.category_name,
                        superCategory: accessory?.product?.categories?.[0]?.name,
                        product_page_url: accessory?.product?.product_page_url
                    };
                });

                consumable?.consumables?.forEach(addon => {
                    addon?.specGroups?.forEach(specGroup => {
                        specGroup?.items?.forEach(item => {
                            const addonKey = `${item?.itemNumber}${item?.stockClass}${item?.oemStockNumber}`;
                            lookup[productSku][addonKey] = {
                                price: parseFloat(item?.sellingPrice)
                            };
                        });
                    });
                });
            });
            return lookup;
        } catch (error) {
            logError(error, false, 'createConsumablesLookup', [consumablesData]);
            return {};
        }
    };

    const updateSelectedDetails = (items, lookup, isAddon, availableConfig = {}) => {
        try {
            Object.keys(items)?.forEach(itemKey => {
                const itemVal = items[itemKey];
                let isAvailable = false;
                if (lookup) {
                    // this condition will tell if main product is available
                    isAvailable = !!lookup?.[itemKey] || itemVal?.itemObj?.forceItem === FORCE_ITEM.YES;
                }
                itemVal.isAvailable = isAvailable;
                if (isAddon) {
                    itemVal.price = lookup?.[itemKey]?.price || itemVal?.price;
                } else {
                    itemVal.itemObj = { ...(itemVal?.itemObj || {}), ...(lookup?.[itemKey] || {}) };
                }
                const availableConfigData = availableConfig[itemKey];
                if (availableConfigData) {
                    const remainingKey = isAddon ? 'addOnsRemainingQuantity' : 'accessoriesRemainingQuantity';
                    availableConfigData[remainingKey] = Math.max(
                        availableConfigData[remainingKey] - items[itemKey]?.qty,
                        0
                    );
                }
            });
        } catch (error) {
            logError(error, false, 'updateSelectedDetails', [items, lookup]);
        }
    };

    const removeUnavailableConsumables = consumables => {
        try {
            if (!consumables) return;
            const keys = Object.keys(consumables);
            for (let key of keys) {
                if (!consumables[key]?.isAvailable) {
                    delete consumables[key];
                }
            }
        } catch (error) {
            logError(error, false, 'removeUnavailableConsumables', [consumables]);
        }
    };

    /**
     * This function will format the stringified data coming from cart details api
     * @param consumables - stringified data from cart details api
     * @returns formated selected items
     */
    const parseConsumablesData = (consumables, removeUnavailable = false) => {
        try {
            if (!consumables) return {};
            const selectedConsumables = {};
            const productsWithConsumables = JSON.parse(consumables);
            productsWithConsumables?.forEach(product => {
                const { cat, class: classId, addons, rentals } = product || {};
                if (removeUnavailable) {
                    removeUnavailableConsumables(addons);
                    removeUnavailableConsumables(rentals);
                }
                if (isObjectWithKeys(addons || {}) || isObjectWithKeys(rentals || {})) {
                    const sku = generateSKU(cat, classId);
                    selectedConsumables[sku] = { addons, rentals };
                }
            });
            return selectedConsumables;
        } catch (error) {
            logError(error, false, 'parseConsumablesData', [consumables]);
            return {};
        }
    };

    const stringifyConsumablesData = consumables => {
        try {
            const formattedConsumables = Object.keys(consumables)?.map(sku => {
                const { catId, classId } = extractCatAndClass(sku);
                return {
                    ...(consumables[sku] || {}),
                    cat: catId,
                    class: classId
                };
            });
            return JSON.stringify(formattedConsumables);
        } catch (error) {
            logError(error, false, 'stringifyConsumablesData', [consumables]);
            return '';
        }
    };

    const updateItemConsumables = async (sku, selectedItems) => {
        try {
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            const response = await updateConsumablesAction({
                updateConsumablesGQL,
                cartId,
                consumables: stringifyConsumablesData({ [sku]: selectedItems })
            });
            if (response?.updateCartAccessories?.message !== SUCCESS?.toUpperCase()) {
                return false;
            }
            const updatedSelectedItems = {
                ...consumables?.selected,
                [sku]: selectedItems
            };
            dispatch({
                type: SET_CONSUMABLES_SELECTED_ITEMS,
                selectedItems: updatedSelectedItems
            });
            dispatch({ type: SET_IS_ESTIMATE_UPDATE_REQUIRED, isEstimateUpdateRequired: true });
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return true;
        } catch (error) {
            logError(error, false, 'updateItemConsumables', [sku, selectedItems]);
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return false;
        }
    };

    const removeItemsConsumables = (productSkus, updateSelectedItems = false) => {
        try {
            const updatedSelectedItems = { ...(consumables?.selected || {}) };
            if (isObjectEmpty(updatedSelectedItems)) return;
            productSkus?.forEach(sku => delete updatedSelectedItems[sku]);
            if (updateSelectedItems) {
                dispatch({
                    type: SET_CONSUMABLES_SELECTED_ITEMS,
                    selectedItems: updatedSelectedItems
                });
            }
            return updatedSelectedItems;
        } catch (error) {
            logError(error, false, 'removeItemsConsumables', [productSkus, updateSelectedItems]);
            return {};
        }
    };

    const bulkUpdateConsumables = async (selectedConsumables = consumables?.selected) => {
        try {
            const response = await updateConsumablesAction({
                updateConsumablesGQL: bulkUpdateConsumablesGQL,
                cartId,
                consumables: stringifyConsumablesData(selectedConsumables)
            });
            return response?.bulkupdateCartAccessories?.message === SUCCESS?.toUpperCase();
        } catch (error) {
            logError(error, false, 'bulkUpdateConsumables', [selectedConsumables]);
            return '';
        }
    };

    const parseAndSaveSelectedConsumables = stringifiedConsumables => {
        try {
            if (!stringifiedConsumables) return;
            const selectedConsumables = parseConsumablesData(stringifiedConsumables, true);
            const selectedRentalQtyAndPrice = getTotalFromConsumables(selectedConsumables);
            dispatch({
                type: SET_CONSUMABLES_SELECTED_ITEMS,
                selectedItems: selectedConsumables
            });
            dispatch({
                type: UPDATE_CONSUMABLES,
                key: 'orderSummaryRentals',
                value: selectedConsumables
            });
            dispatch({
                type: UPDATE_CONSUMABLES,
                key: 'selectedRentalQtyAndPrice',
                value: selectedRentalQtyAndPrice
            });
        } catch (error) {
            logError(error, false, 'parseAndSaveSelectedConsumables', [stringifiedConsumables]);
        }
    };

    /** call consumables api and finds all the unavailable consumables */
    const getUnavailableConsumablesOnCapChange = async (items, pcId) => {
        try {
            if (isObjectEmpty(consumables?.selected)) return {};
            const itemsSku = items?.map(item => item?.product?.sku);
            let payloadForConsumables = {
                pcId,
                account: handlePayloadAccount(),
                jobNumber: handlePayloadJobNumber(),
                items: itemsSku
            };
            const response = await getConsumablesAction({
                consumablesGQL,
                payloadForConsumables
            });
            const consumablesData = await excludeForcedFuelItems(response);
            const unavailableConsumables = getUnavailableConsumables(consumablesData);
            return { consumablesData, unavailableConsumables };
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getUnavailableConsumablesOnCapChange', [pcId]);
            } else {
                logError(error, false, 'getUnavailableConsumablesOnCapChange', [pcId]);
            }
            return {};
        }
    };

    const getUnavailableConsumables = consumablesData => {
        try {
            const unavailableAccessoriesMap = {};
            const unavailableAddonsMap = {};
            const lookup = createConsumablesLookup(consumablesData);
            Object.keys(lookup)?.forEach(itemSku => {
                const selectedConsumables = consumables?.selected?.[itemSku];
                if (selectedConsumables) {
                    const addons = selectedConsumables?.addons || {};
                    const accessories = selectedConsumables?.rentals || {};
                    updateUnavailableData(addons, lookup?.[itemSku], unavailableAddonsMap, false);
                    updateUnavailableData(accessories, lookup?.[itemSku], unavailableAccessoriesMap, true);
                }
            });
            return {
                unavailableAccessories: Object.values(unavailableAccessoriesMap),
                unavailableAddons: Object.values(unavailableAddonsMap)
            };
        } catch (error) {
            logError(error, false, 'getUnavailableConsumables', [consumablesData]);
            return {};
        }
    };

    /** find and add unavailable items in unavailableMap */
    const updateUnavailableData = (items, lookup, unavailableMap, isAccessory) => {
        try {
            Object.keys(items)?.forEach(itemKey => {
                const data = items?.[itemKey] || {};
                if (!unavailableMap[itemKey] && !lookup?.[itemKey] && data?.itemObj?.forceItem !== FORCE_ITEM.YES) {
                    unavailableMap[itemKey] = data;
                    if (isAccessory && data) {
                        unavailableMap[itemKey]['sku'] = itemKey;
                    }
                }
            });
        } catch (error) {
            logError(error, false, 'updateUnavailableData', [items, lookup, unavailableMap]);
            return {};
        }
    };

    const isOptionalItemNotSelected = availableItems => {
        const selectedConsumables = consumables?.selected || {};
        const availableItemsLookup = {};
        availableItems.forEach(item => {
            const sku = item?.product?.sku;
            if (!availableItemsLookup[sku]) {
                availableItemsLookup[sku] = true;
            }
        });

        for (let consumableSku in selectedConsumables) {
            if (Object.hasOwn(selectedConsumables, consumableSku)) {
                if (!availableItemsLookup[consumableSku]) {
                    continue;
                }

                const accessories = selectedConsumables[consumableSku]?.rentals || {};
                const addons = selectedConsumables[consumableSku]?.addons || {};

                for (let key in accessories) {
                    if (accessories[key]?.itemObj?.forceItem !== FORCE_ITEM.YES) {
                        return false;
                    }
                }

                for (let key in addons) {
                    if (addons[key]?.itemObj?.forceItem !== FORCE_ITEM.YES) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const isOptionalItemsAvailable = () => {
        const consumablesData = consumables?.data || [];
        for (let data of consumablesData) {
            const accessories = data?.rentables || [];
            const addons = data?.consumables || [];

            for (let accessory of accessories) {
                if (accessory?.forceItem !== FORCE_ITEM.YES) {
                    return true;
                }
            }

            for (let addon of addons) {
                const specGroups = addon?.specGroups || [];
                for (let specGroup of specGroups) {
                    const items = specGroup?.items || [];
                    for (let item of items) {
                        if (item?.forceItem !== FORCE_ITEM.YES) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const isAvailableOptionalItemsNotSelected = availableItems => {
        try {
            if (!isOptionalItemsAvailable()) {
                return false;
            }
            return isOptionalItemNotSelected(availableItems);
        } catch (error) {
            logError(error, false, 'isAvailableOptionalItemsNotSelected');
            return false;
        }
    };

    const api = {
        getConsumablesForAddToCart,
        getSelectedConsumablesAvailability,
        updateItemConsumables,
        removeItemsConsumables,
        bulkUpdateConsumables,
        parseAndSaveSelectedConsumables,
        getUnavailableConsumablesOnCapChange,
        isAvailableOptionalItemsNotSelected
    };

    return [api];
};

export default useConsumables;
