export const QTY_LIMIT = 25;
export const YES = 'YES';
export const NO = 'NO';
export const REQUEST = 'request';
export const RENT = 'rent';
export const NOT_AVAILABLE = 'not available';
export const IMAGE_SPECS = {
    HEIGHT: 56,
    WIDTH: 56
}
export const imageStaticPath = 'catClass';
