import React from "react";
import { object } from "prop-types";
import { useIntl } from "react-intl";
import RequiredAccessoriesAddonsDetails from "../../../requiredAccessoriesAddonsListing/RequiredAccessoriesAddonsDetails";

const ForcedItemsDetails = (props) => {
  const { forcedItemDetails = {} } = props || {};

  const intl = useIntl();

  return (
        <>
          <div className="rentals_list_container">
            <div className="rentals_list">
                <RequiredAccessoriesAddonsDetails  forcedItemsDetails={forcedItemDetails} isModalView={true} title={intl.formatMessage({
                  id: "add-to-cart-modal-accessories-section-title",
                })}/>
            </div>
          </div>
          <div className="rentals_modal_content">
            <span>
              {intl.formatMessage({
                id: "add-to-cart-modal-accessories-add-on-desc",
              })}
            </span>
          </div>
        </>
  );
};

ForcedItemsDetails.propTypes = {
    forcedItemDetails: object,
};

export default React.memo(ForcedItemsDetails);
