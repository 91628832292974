import React from "react";
import { string } from "prop-types";
import { getAccessoriesPriceToShow } from "../../../../global/utils/commonUtils";

const RentalDetail = (props) => {
  const { label = '', value = '', type = '' } = props || {};
  return (
    <div className={`rentals_${type}`}>
      <label htmlFor={type} className={`rentals_${type}_label`}>
        {label}
      </label>
      <span className={`rentals_${type}_value`}>
        {type === "price" ? getAccessoriesPriceToShow(value) : value}
      </span>
    </div>
  );
};

RentalDetail.propTypes = {
    label: string, 
    value: string, 
    type: string
};

export default React.memo(RentalDetail);
