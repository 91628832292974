import React from "react";
import { bool, number, string } from "prop-types";
import { useIntl } from "react-intl";
import { useCartState } from "../../../../../contexts/cart";
import RentalDetail from "../components/RentalDetail";
import RequiredAccessoriesAddonsInfoModal from "../../requiredAccessoriesAddonsInfoModal/RequiredAccessoriesAddonsInfoModal";

const RentalItem = (props) => {
  const {
    isModalView = false,
    name = "",
    minQuantity = 1,
    sellingPrice = 0,
  } = props || {};
  const [{ isAccessoriesRatesLoading }] = useCartState();
  const intl = useIntl();


  return (
    <div className="rentals_container">
      <div className="rentals_title_container">
        <span className="rentals_title">{name}</span>
        {isModalView && <RequiredAccessoriesAddonsInfoModal isModalView={isModalView} title={intl.formatMessage({ id: "addons:required-btn-label" })}/>}
      </div>

      <div className="rentals_details">
        <RentalDetail
          label={intl.formatMessage({ id: "pdp:required-rentals-qty-label" })}
          value={minQuantity}
          type="qty"
        />
        {isAccessoriesRatesLoading ? (
          <div className="accessories-rates-loading  shimmer"></div>
        ) : (
          <RentalDetail
            label={intl.formatMessage({
              id: "pdp:required-rentals-price-label",
            })}
            value={sellingPrice}
            type="price"
          />
        )}
      </div>
    </div>
  );
};

RentalItem.propTypes = {
  name: string,
  minQuantity: number,
  sellingPrice: number,
  isModalView: bool,
};

export default React.memo(RentalItem);
