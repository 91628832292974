import { cookieValue } from '../../aem-core-components/utils/cookieUtils';
import { SAVE_FOR_LATER } from '../../components/global/constants';
import { getUserAccountFromCookies, storeCheckoutData } from '../../components/global/utils/commonUtils';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

export const cartInitialState = {
    isEditing: false,
    isLoading: false,
    isCartLoading: false,
    editItem: {},
    cartId: cookieValue(STORAGE_CONFIG.COOKIES.CIF_CART) || null,
    cart: null,
    errorMessage: null,
    cartOverlay: 'view_cart',
    cartStack: ['view_cart'],
    animateHeader: {
        showHeader: false,
        showGradient: false
    },
    startDateClick: false,
    endDateClick: false,
    calendarDateInteraction: false,
    isCheckoutEditResetComplete: false,
    guestUserDetails: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        marketoFeed: false
    },
    userAccount: getUserAccountFromCookies(),
    modal: {
        title: '',
        content: '',
        hideIcon: false,
        isOpen: false,
        customClass: ''
    },
    howToGetYourOrderDetails: {
        selectedPickupTime: '',
        selectedStartDate: '',
        selectedReturnTime: '',
        selectedEndDate: '',
        bestMatchStoresData: { data: [] },
        closestStoresData: { data: [] },
        selectedDeliveryTime: {
            slot: '',
            label: ''
        },
        selectedPickUpTime: {
            slot: '',
            label: ''
        },
        selectedStoreDetails: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDATA) || {},
        estimatedTotal: 0,
        pickupType: 1,
        someoneElse: {
            firstname: '',
            lastname: '',
            phone: '',
            email: ''
        },
        isBestMatch: true,
        storesAPIStatus: 500
    },
    isCreditNewAddress: false,
    startDateSlots: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.STARTDATESLOTS) || {},
    endDateSlots: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.ENDDATESLOTS) || {},
    pickUpTimeSlots: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.PICKUPTIMESLOTS) || [],
    returnTimeSlots: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.RETURNTIMESLOTS) || [],
    consumables: {
        data: [],
        selected: {},
        availableQuantity: {},
        savedAvailableQuantity: {},
        rentablesWithPrice: {},
        saveAndContinue: false,
        selectedRentalEquipment: {},
        showRentalEquipmentScreen: false,
        isRentalEquipmentsAdded: false,
        orderSummaryRentals: {},
        selectedRentalQtyAndPrice: {
            totalRentalQty: 0,
            totalAddonPrice: 0,
            totalAddonQty: 0,
            totalRentalPrice: 0
        },
        dataWithoutPrice: null
    },
    optionalPlan: {
        initialRppSelection: false,
        initialFuelChargeSelection: false,
        isRPPChecked: false,
        isFuelChargeChecked: false,
        isRPPCheckboxVisible: true,
        isFuelCheckboxVisible: true,
        isEdit: false
    },
    // mock for payment starts...
    paymentData: {
        selectedPaymentMethod: 0,
        selectedPaymentDetails: {}
    },
    sessionAPIResponse: {},
    // mock for payment ends.
    projectInfo: {
        primaryContactName: '',
        phoneNumber: '',
        accessNotes: '',
        poNumber: ''
    },
    submitReservation: {
        isSubmitReservationScreenVisible: false,
        confirmationType: 3,
        refNumber: 0,
        isPasswordModalOpen: false
    },
    currentOffset: storeCheckoutData('timeZoneResponse')?.offset || '',
    timeZoneID: storeCheckoutData('timeZoneResponse')?.timeZoneID || '',
    homeAssetsRates: {},
    creditCustomAccountInformation: {},
    customBillingFields: [],
    isRatesLoading: true,
    paymentTokenData: {},
    clickedMakeChanges: false,
    isSourcesLoading: false,
    cashProfileLatLongUpdated: false,
    orderEstimatesFinalTotal: 0,
    showDelinquentModal: false,
    isReservationClicked: false,
    nearbyPCs: [],
    selectedCombinedTabData: [],
    isCombinedTabLoading: true,
    cidPcList: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST) || '[]') || [],
    atpPcList: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
    isCombinedTabsLoading: false,
    showAccountOverlay: false,
    staticTileProductDetails: [],
    isItemPCAdding: undefined,
    isBulkAddToCartModalOpen: false,
    selectedTransmitOrSave: SAVE_FOR_LATER,
    orderSummaryDetails: {
        checkoutSubtotal: null,
        rentalSubtotal: null,
        purchaseSubtotal: null,
        deliveryCharges: null,
        pickupCharges: null,
        deliveryPickUpCharges: null,
        rentalProtectionPlan: null,
        prepayFuelOption: null,
        environmentalServiceFee: null,
        otherFees: null,
        taxes: null,
        estimatedSubtotal: null,
        allOtherCharges: null
    },
    recomputeItemsAvailability: false,
    isEstimateUpdateRequired: false,
    editQuote: {
        isUpdateAvailable: false
    },
    error: {
        timeZoneError: '',
        storesError: '',
        startDateError: '',
        endDateError: ''
    },
    loaders: {
        isEstimatesLoading: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATES_LOADING))
    },
    cartItemsAvailabilityChangedReason: '',
    unavailableCartItemsPerPc: null, // will use null to check if calculation is done or not,
    sortedChronosPcs: [],
    accessoriesRates: {},
    isAccessoriesRatesLoading: false,
    isConsumablesCoachmarkOpen: false
};
