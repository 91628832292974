import React, { memo } from 'react';
import { bool, func, string, array } from 'prop-types';
import ReactModal from 'react-modal';
import { useIntl } from 'react-intl';
import useMedia from '../../../../hooks/useMedia';
import ProductStatusDetails from './productStatusDetails/ProductStatusDetails';
import { SideDrawerModal as StorybookSideDrawerModal } from '@sunbeltrentals/sbr-global-components';
import { BackHeader } from '../../atoms/backHeader';
import Button from '../../atoms/button/button';
import { getStaticAccessoriesImages } from '../../utils/commonUtils';
import Close from '../../../../resources/images/close.svg';
import CloseIcon from '../../../../resources/images/cross_24x24.svg';
import './rentalChangeConfirmationModal.scss';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';

const RentalChangeConfirmationModal = ({
    isOpen,
    handleModalClose,
    handleConfirmation,
    handleBackNavigation,
    portalClassName,
    productList,
    accessoriesList = [],
    addonsList = []
}) => {
    const intl = useIntl();
    const mediaType = useMedia();
    const isAccessoryAddonPresent = accessoriesList?.length > 0 || addonsList?.length > 0;

    const header = () => (
        <div className="rental-change-modal__header">
            <div id="rentalChangeTitle" className="sr-only">
                {intl.formatMessage({ id: 'rental-change-modal:header-sr-label' })}
            </div>
            {mediaType === MEDIA_TYPE.MOBILE ? (
                <h5>{intl.formatMessage({ id: 'rental-change-modal:header' })}</h5>
            ) : (
                <div className="rental-change-modal__header-headline">
                    <h6>{intl.formatMessage({ id: 'rental-change-modal:header' })}</h6>
                    <Button
                        className="rental-change-modal__header-close"
                        onClick={handleModalClose}
                        customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                        <Close tabIndex={-1} aria-hidden={true} />
                    </Button>
                </div>
            )}
            {mediaType !== MEDIA_TYPE.MOBILE && !isAccessoryAddonPresent && <hr className="divider" />}
        </div>
    );

    const renderAddonList = () => (
        <>
            {addonsList?.map(unavailableAddon => (
                <ProductStatusDetails
                    key={unavailableAddon?.name}
                    productName={unavailableAddon?.name}
                    showImage={false}
                />
            ))}
        </>
    );

    const renderAccessoryList = () => (
        <>
            {accessoriesList?.map(unavailableAccessory => (
                <ProductStatusDetails
                    key={unavailableAccessory?.name}
                    productName={unavailableAccessory?.name}
                    thumbnailUrl={getStaticAccessoriesImages(unavailableAccessory?.sku)}
                />
            ))}
        </>
    );

    const renderProductList = () => (
        <>
            {productList?.map(unavailableProduct => (
                <ProductStatusDetails
                    key={unavailableProduct?.product?.sku}
                    productName={unavailableProduct?.product?.name}
                    thumbnailUrl={unavailableProduct?.product?.thumbnail?.url}
                />
            ))}
        </>
    );

    const content = () => {
        if (!isAccessoryAddonPresent) {
            return <div className="rental-change-modal__content">{renderProductList()}</div>;
        }
        return (
            <div className="rental-change-modal__content accessory-addon-present">
                {productList?.length > 0 && (
                    <strong className="equipment-heading">
                        {intl.formatMessage({ id: 'equipment:equipment-heading-text' })}
                    </strong>
                )}
                {renderProductList()}
                <strong className="accessory-addon-heading">
                    {intl.formatMessage({ id: 'checkout:accessories-and-addons-title' })}
                </strong>
                {renderAccessoryList()}
                {renderAddonList()}
            </div>
        );
    };

    const footer = () => (
        <div className="rental-change-modal__footer">
            <Button
                className="button button-primary button-block"
                type="button"
                onClick={() => handleConfirmation(true)}
                buttonAriaLabel={intl.formatMessage({ id: 'rental-change-modal:yes-btn' })}>
                {intl.formatMessage({ id: 'rental-change-modal:yes-btn' })}
            </Button>
            <Button
                className="button button-outline-primary button-block"
                type="button"
                onClick={() => handleConfirmation(false)}
                buttonAriaLabel={intl.formatMessage({ id: 'rental-change-modal:no-btn' })}>
                {intl.formatMessage({ id: 'rental-change-modal:no-btn' })}
            </Button>
        </div>
    );

    const drawerContent = () => (
        <>
            {header()}
            {content()}
        </>
    );

    const drawerHeader = () => (
        <>
            <BackHeader closeModal={handleBackNavigation} />
            <Button
                className="rental-change-modal__drawer-header__close"
                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                onClick={handleModalClose}>
                <CloseIcon aria-hidden="true" tabIndex="-1" />
            </Button>
        </>
    );

    const renderSideDrawer = () => (
        <StorybookSideDrawerModal
            isModalOpen={isOpen}
            header={drawerHeader()}
            content={drawerContent()}
            footer={footer()}
            modalFooterClass="rental-change-modal__drawer-footer"
            modalHeaderClass="rental-change-modal__drawer-header"
            modalContentClass="rental-change-modal__drawer-content"
            alignLeft={true}
            showCloseIcon={false}
        />
    );

    return (
        <>
            {mediaType === MEDIA_TYPE.MOBILE ? (
                renderSideDrawer()
            ) : (
                <ReactModal
                    role="none"
                    aria={{
                        labelledby: 'rentalChangeTitle'
                    }}
                    isOpen={isOpen}
                    portalClassName={portalClassName}
                    className="rental-change-modal">
                    {header()}
                    {content()}
                    {footer()}
                </ReactModal>
            )}
        </>
    );
};

RentalChangeConfirmationModal.defaultProps = {
    productList: [],
    isOpen: false,
    handleModalClose: () => {},
    handleConfirmation: () => {},
    portalClassName: '',
    handleBackNavigation: () => {}
};

RentalChangeConfirmationModal.propTypes = {
    productList: array,
    handleModalClose: func,
    handleConfirmation: func,
    isOpen: bool,
    portalClassName: string,
    handleBackNavigation: func,
    accessoriesList: array,
    addonsList: array
};

export default memo(RentalChangeConfirmationModal);
