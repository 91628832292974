export const FACTORYSOURCE = {
    CHECKOUT: 'checkout',
    QUOTE: 'quote',
    CART: 'cart'
};

export const PICKUP = 'PICKUP';
export const DELIVERY = 'DELIVERY';
export const FUEL_CONVENIENCE_CHARGE = 'FUEL CONVENIENCE CHARGE';
export const TRANSPORTATION_SURCHARGE = 'TRANSPORTATION SURCHARGE';
export const ENVIRONMENTAL = 'ENVIRONMENTAL';
export const ENVIRON = 'ENVIRON';
export const STOCK_CLASS_DELIVERY = 'DEL';
export const STOCK_CLASS_FUEL = 'FUEL';
export const STOCK_CLASS_MISCELLANEOUS = 'MISC';
export const STOCK_CLASS_LABOR = 'LABOR';
export const LINE_ITEM_TYPE_SALES = 'SALES';
export const LINE_ITEM_TYPE_RENTAL = 'RENTAL';
export const LINE_ITEM_TYPE_MISC = 'MISC';

export const COST_CATEGORIES = [
    'rentalSubtotal',
    'purchaseSubtotal',
    'deliveryPickUpCharges',
    'rentalProtectionPlan',
    'prepayFuelOption',
    'environmentalServiceFee',
    'otherFees'
];

export const FIELD_MAPPINGS = {
    RENTAL_SUBTOTAL: 'rentalSubTotal',
    PURCHASE_SUBTOTAL: 'purchasesSubTotal',
    RPP_FEES: 'rppFees',
    OTHER_FEES: 'otherFees',
    TAXES: 'taxes',
    QUOTE_TOTAL_AMOUNT: 'quoteTotalAmount'
};

export const SHOW_FIELDS = {
    HEADER: 'header',
    FOOTER: 'footer',
    TAXES: 'taxes',
    SUB_HEADER: 'subHeader'
}