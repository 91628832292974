import React, { memo } from 'react';
import { bool, string } from 'prop-types';
import ProductStatusChangeAlert from '../productStatusChangeAlert/ProductStatusChangeAlert';
import ProductImage from '../../../atoms/productImage';
import './productStatusDetails.scss';
import { PRODUCT_CHANGE_STATUS } from '../constants';

const { UNAVAILABLE } = PRODUCT_CHANGE_STATUS;

const ProductStatusDetails = ({
    productName,
    thumbnailUrl = '',
    unavailableAlertMsg,
    dataTestid = '',
    showImage = true
}) => {
    return (
        <div className="product-status-details__wrapper" data-testid={dataTestid}>
            {showImage && (
                <ProductImage
                    url={thumbnailUrl}
                    defaultViewCardType="minicart"
                    name={productName}
                    className="product-status-details__image"
                />
            )}
            <div className="product-status-details">
                {productName}
                <ProductStatusChangeAlert status={UNAVAILABLE} unavailableAlertMsg={unavailableAlertMsg} />
            </div>
        </div>
    );
};

ProductStatusDetails.propTypes = {
    productName: string,
    thumbnailUrl: string,
    unavailableAlertMsg: string,
    dataTestid: string,
    showImage: bool
};

export default memo(ProductStatusDetails);
