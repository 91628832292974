import { logError } from '../../global/utils/logger';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import {
    EQUIPMENT_RENTAL,
    HOME_PAGE,
    LOCATION,
    PDP,
    PLP,
    SEARCH,
    SEARCH_RESULTS_PAGE
} from '../../../constants/screenConstants';
import { RECENTLY_VIEWED } from '../../global/modules/recentlyViewed/constants';

export const recentlyViewedTracker = productId => {
    const recentProducts = localStorage.getItem(RECENTLY_VIEWED);
    if (recentProducts) {
        let productList = JSON.parse(recentProducts);
        productList = productList?.filter(value => {
            if (value !== productId) {
                return value;
            }
        });
        productList.unshift(productId);
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productList));
    } else {
        const productArray = [];
        productArray[0] = productId;
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productArray));
    }
};

export const getPageReferrer = () => {
    var referrerLink = document.referrer.split('/');
    if (document.referrer.includes(EQUIPMENT_RENTAL)) {
        if (isNaN(referrerLink[referrerLink.indexOf(EQUIPMENT_RENTAL) + 1])) {
            return PLP.toLowerCase();
        } else return PDP;
    } else if (document.referrer.includes(SEARCH)) {
        return SEARCH_RESULTS_PAGE.toLowerCase();
    } else if (document.referrer.includes(LOCATION)) {
        return VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
    } else if (document.referrer === '' || document.referrer === window?.location?.href) {
        return VARIABLE_CONFIG.ECOMMERCE.UNDEFINED;
    } else if (referrerLink.length <= 4) {
        return HOME_PAGE.toLowerCase();
    }
};

export const getProductId = () => {
    let { catsku } = document.querySelector('.block--pdp-hero')?.dataset || {};
    if (catsku) {
        return catsku;
    } else {
        return null;
    }
};

export const splitItemsIntoTwoColumns = (items, isMobile) => {
    try {
        if (isMobile) {
            return [items, []];
          }
        
          const halfLength = Math.ceil(items?.length / 2);
          const firstColumn = items.slice(0, halfLength);
          const secondColumn = items.slice(halfLength);
        
          return [firstColumn, secondColumn];

    } catch (error) {
        logError(error, false, 'splitItemsIntoTwoColumns');
        return [[],[]];
    }
};
