import { uuid4 } from '@sentry/utils';
import axios from 'axios';
import config from '../../../../components/App/config';

var useCookieValue = cookieName => {
    if (!cookieName || cookieName?.length === 0) {
        return '';
    }
    const value = cookieValue(cookieName);
    const setCookieValue = (value, age, isSession) => {
        // let domainNameQASunbelt = 'qa-www.sunbeltrentals.com';
        // let domainNameDevSunbelt = 'dev-www.sunbeltrentals.com';
        // let domainNameLocal = 'localhost';
        const cookieSettingSunbelt = !isSession
            ? `path=/; Max-Age=${age !== undefined ? age : 3600 * 24}; SameSite=None; Secure`
            : `path=/; SameSite=None; Secure`;
        // const cookieSettingDevSunbelt = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        // const cookieSettingLocal = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        document.cookie = `${cookieName}=${value};${cookieSettingSunbelt}`;
    };
    return [value, setCookieValue];
};
var USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit'
};
var AUTHORITY_TYPE = {
    DOTCOM: 'DOTCOM',
    P2P: 'P2P',
    PUNCHOUT: 'PUNCHOUT'
};
var STORAGE_CONFIG = {
    LOCAL_STORAGE: {
        ISCREDITNEWADDRESS: 'isCreditNewAddress',
        VIEWCART: 'viewCart',
        COMPANYID: 'companyID',
        GUID: 'guid',
        ISCHECKOUTLOADING: 'isCheckoutLoading',
        PROJECTDETAILS: 'projectDetails',
        CHECKOUTREFERRER: 'checkoutReferrer',
        USER_LOGIN: 'user-login',
        USERTYPE: 'userType',
        FIRSTNAME: 'firstName',
        YOURDETAILS: 'yourDetails',
        SELECTEDSTOREDETAILS: 'selectedStoreDetails',
        JOBSITE_SEARCH_KEYWORD: 'jobsiteSearchKeyword',
        SELECTED_ACCOUNT_ID: 'selectedAccountId',
        IP_ADDRESS: 'ipAddress',
        CARTTOTALQUANTITY: 'cartTotalQuantity',
        ISNEWADDRESSONOVERLAY: 'isNewAddressOnOverlay',
        ISINSTOREPICKUP: 'isInStorePickup',
        LOCATIONSEARCHFIELDS: 'locationSearchFields'
    },
    SESSION_STORAGE: {
        MKTOLOGGEDIN: 'mktoLoggedIn',
        OVERRIDEPC: 'overridePc',
        STARTDATE: 'startDate',
        ENDDATE: 'endDate',
        SBMID: 'sbmid',
        SBSID: 'sbsid',
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        ISADDONVISIBLE: 'isAddOnVisible',
        PAYMENTSTEP: 'paymentStep',
        FORMSTEP: 'formStep',
        LOCATIONPCOBJ: 'locationPCObj',
        SOURCEPCOBJ: 'sourcePCObj',
        HOMEASSETSRATES: 'homeAssetsRates',
        PROJECTLOADINGTIMERID: 'projectLoadingTimerId',
        MARKETOFEED: 'marketoFeed',
        ISUSERPRESUBSCRIBED: 'isUserPreSubscribed',
        ISSOURCESLOADING: 'isSourcesLoading',
        USERTYPE: 'userType',
        USERPROFILE: 'userProfile'
    },
    COOKIES: {
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        SBUID: 'sbuid',
        SBAID: 'sbaid',
        CIF_CART: 'cif.cart',
        ACCESS_TOKEN: 'access_token',
        REFRESH_TOKEN: 'refresh_token',
        SETTINGS: 'Settings',
        USERTYPE: 'userType',
        ACCESSTOKEN: 'accesstoken',
        COMPANYID: 'companyId',
        FIRSTNAME: 'firstName',
        REFRESHTOKEN: 'refreshtoken',
        SESSIONID: 'sessionid',
        GUESTSESSIONID: 'guestsessionid',
        LOGINTOKEN: 'loginToken',
        PUNCHOUT_REDIRECT_URL: 'punchout_redirect_url',
        TOKENEXPTIME: 'tokenExpTime',
        AUTHORITY_TYPE: 'authority_type'
    }
};
var MY_ACCOUNT_MOB_CLASS = {
    cashUser: 'cashUser',
    creditUser: 'creditUser',
    p2puserMobile: 'p2puserMobile'
};
var MUTATION_REVOKE_TOKEN_STRING = `
    mutation {
        revokeCustomerToken {
            result
        }
    }
`;

var AEM_COOKIE_CLEAR_ENDPOINT = '/services/logout';

var cookieValue = cookieName => {
    let name = cookieName + '=';
    let cookieVal = document.cookie;
    let ca = cookieVal.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
var deleteCookie = cookieName => {
    return (document.cookie =
        cookieName + '=; Path=/; SameSite=None; Secure; Max-Age=0; Expires=Thu, 01 Jan 1970 00:00:01 GMT;');
};
var getProjectsFromCookie = () => {
    var settingsCookie = cookieValue('Settings');
    var projectObj = {};
    var projectsInfo = settingsCookie?.split('&');
    for (let i in projectsInfo) {
        let cookiePair = projectsInfo[i].split('=');
        projectObj[cookiePair[0]] = decodeURIComponent(cookiePair[1]);
    }
    return projectObj;
};
var deriveAuthenticatedUser = () => {
    const projectCookies = getProjectsFromCookie();
    /* if account present, then credit user else cash user */
    if (projectCookies?.CurrentWynneAccount) {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CREDIT);
        return USER_TYPE.CREDIT;
    } else {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CASH);
        return USER_TYPE.CASH;
    }
};
var useCheckUser = () => {
    const userType = USER_TYPE.GUEST;
    let userTypeStorage = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE);
    /* validate subsequent userType checking from localStorage */
    if (userTypeStorage) {
        return userTypeStorage;
    } else {
        /* validate initial authenticated user or guest user checking */
        const refreshToken = cookieValue(STORAGE_CONFIG.COOKIES.REFRESHTOKEN);
        /* validate normal authenticated user checking */
        if (refreshToken) {
            return deriveAuthenticatedUser();
        } else if (window.location != window.parent.location) {
            /* validate punchout authenticated user checking */
            const punchoutRefreshToken = cookieValue(STORAGE_CONFIG.COOKIES.REFRESH_TOKEN);
            /* check if refresh token is present for punchout user */
            if (punchoutRefreshToken) {
                return deriveAuthenticatedUser();
            } else {
                return userType;
            }
        } else {
            /* validate guest user checking */
            return userType;
        }
    }
};
const getElement = selector => {
    try {
        const element = document.querySelector(selector);
        return element;
    } catch (error) {
        return null;
    }
};

const getAllElements = selector => {
    try {
        const elements = document.querySelectorAll(selector);
        return elements;
    } catch (error) {
        console.error(`Error querying selector ${selector}:`, error);
        return [];
    }
};

const getElementIfExists = selector => {
    try {
        const element = getElement(selector);
        return element ? element : {};
    } catch (error) {
        return {};
    }
};
const getAllElementsIfExists = selector => {
    const element = getAllElements(selector);
    return element ? element : {};
};
var generateLoginHeader = (label, accountlabel, grettingMessage) => {
    return `
    <span class="dropdown-account-icon icon icon-person-icon" aria-hidden="true"></span>
    <span class="default_greetingMsg dropdown-account" data-testid="header_account_label">${label || ''}
    ${accountlabel ? `<span>${accountlabel}</span>` : ''}</span>
    ${
        grettingMessage
            ? `<span class="greetingMsg dropdown-account usergreeting" data-testid="userprofilename_link">${grettingMessage}</span>`
            : ''
    }
`;
};

const toggleDropdowns = (showCash, showCredit, showGuest, showP2p) => {
    var cashUserDesktop = getElementIfExists('.cashuserdesktop');
    var guestUserDesktop = getElementIfExists('.guestuserdesktop');
    var creditUserDesktop = getElementIfExists('.credituserdesktop');
    var p2pUserDesktop = getElementIfExists('.p2puserdesktop');
    cashUserDesktop?.classList?.toggle('display-dropdown', showCash);
    creditUserDesktop?.classList?.toggle('display-dropdown', showCredit);
    guestUserDesktop?.classList?.toggle('display-dropdown', showGuest);
    p2pUserDesktop?.classList?.toggle('display-dropdown', showP2p);
};

/**
 * authority_type : p2p => for P2P flow
 * authority_type : punchout_old => for old Punchout flow
 */
var useCheckAuthorityType = () => {
    const authorityType = cookieValue(STORAGE_CONFIG.COOKIES.AUTHORITY_TYPE)?.toLowerCase();
    
    const authorityTypeMap = {
        p2p: AUTHORITY_TYPE.P2P,
        punchout_old: AUTHORITY_TYPE.PUNCHOUT
    };

    return authorityTypeMap[authorityType] || AUTHORITY_TYPE.DOTCOM;
};

const dropdownMobItems = getAllElementsIfExists('.d-desktop-none .authenticated_user_mob .side__submenu-account ul li');
const isDomElement = el => el instanceof Element;

var handleLogin = userType => {
    var [punchOutAccessToken] = useCookieValue('access_token');
    const authorityType = cookieValue(STORAGE_CONFIG.COOKIES.AUTHORITY_TYPE)?.toLowerCase();
    var [punchoutRefreshToken] = useCookieValue('refresh_token');
    var guestUserMob = getElementIfExists('.side__menu-body-bottom .guestUser_mob');
    var authenticatedUserMob = getElementIfExists('.side__menu-body-bottom .authenticated_user_mob');
    var mobileCashUser = getAllElements('.side__menu-body-bottom .authenticated_user_mob .cashUser');
    var mobileCreditUser = getAllElements('.side__menu-body-bottom .authenticated_user_mob .creditUser');
    var headerSearchRight = getElementIfExists('.header__search__right');
    var headerSearchRightButton = isDomElement(headerSearchRight)
        ? headerSearchRight?.querySelector('.header__search__right-account')
        : null;
    var headerSearchRightButtonDataSet = headerSearchRightButton?.dataset;

    if (headerSearchRightButtonDataSet) {
        var accountlabel = headerSearchRightButtonDataSet?.accountlabel;
        var accountlabel2 = headerSearchRightButtonDataSet?.accountlabel2;
        var loggedinAccountLabel = headerSearchRightButtonDataSet?.loggedinaccountlabel;
        var loggedinAccountLabel2 = headerSearchRightButtonDataSet?.loggedinaccountlabel2;
        var greetingMsgValue = headerSearchRightButtonDataSet?.greetingmessage;
    }
    
    if ((punchOutAccessToken && punchoutRefreshToken) || authorityType === 'p2p') {
        loginHandlerForPunchout(authorityType);
    } else {
        switch (userType) {
            case 'cash':
                guestUserMob?.classList?.add('guestUser_mobhide');
                authenticatedUserMob?.classList?.add('authenticatedUserMob--show');
                updateMobDropdownItems(MY_ACCOUNT_MOB_CLASS.cashUser);
                mobileCreditUser?.forEach(creditUser => creditUser?.classList?.add('creditUser--none'));
                if (headerSearchRightButton) {
                    headerSearchRightButton.innerHTML = generateLoginHeader(
                        loggedinAccountLabel,
                        loggedinAccountLabel2,
                        greetingMsgValue
                    );
                }
                toggleDropdowns(true, false, false, false);

                break;
            case 'credit':
                guestUserMob?.classList?.add('guestUser_mobhide');
                authenticatedUserMob?.classList?.add('authenticatedUserMob--show');
                updateMobDropdownItems(MY_ACCOUNT_MOB_CLASS.creditUser);
                mobileCashUser?.forEach(cashUser => cashUser?.classList?.add('cashUser--none'));
                if (headerSearchRightButton) {
                    headerSearchRightButton.innerHTML = generateLoginHeader(
                        loggedinAccountLabel,
                        loggedinAccountLabel2,
                        greetingMsgValue
                    );
                }
                toggleDropdowns(false, true, false, false);

                break;
            default:
                if (headerSearchRightButton) {
                    headerSearchRightButton.innerHTML = generateLoginHeader(accountlabel, accountlabel2);
                }
                toggleDropdowns(false, false, true, false);
        }
    }
};
var loginHandlerForPunchout = authorityType => {
    try {
        var headerSearchRight = getElementIfExists('.header__search__right');
        var headerSearchRightButton = isDomElement(headerSearchRight)
            ? headerSearchRight?.querySelector('.header__search__right-account')
            : null;
        if (authorityType === 'p2p') {
            var guestUserMob = getElementIfExists('.side__menu-body-bottom .guestUser_mob');
            var authenticatedUserMob = getElementIfExists('.side__menu-body-bottom .authenticated_user_mob');
            guestUserMob?.classList?.add('guestUser_mobhide');
            authenticatedUserMob?.classList?.add('authenticatedUserMob--show');
            updateMobDropdownItems(MY_ACCOUNT_MOB_CLASS.p2puserMobile);
        }
        if (headerSearchRightButton) {
            var headerSearchRightButtonDataSet = headerSearchRightButton?.dataset;
            var loggedinAccountLabel = headerSearchRightButtonDataSet?.loggedinaccountlabel;
            var loggedinAccountLabel2 = headerSearchRightButtonDataSet?.loggedinaccountlabel2;
            headerSearchRightButton.innerHTML = generateLoginHeader(loggedinAccountLabel, loggedinAccountLabel2);
        } 
        toggleDropdowns(false, false, false, true);
    } catch (error) {
        console.error('An error occurred:', error);
    }
};
const updateMobDropdownItems = userType => {
    dropdownMobItems?.forEach(item => {
        if (item?.classList?.contains(userType) || item?.classList?.contains('side__menu-item-top')) {
            item?.classList?.add('display_p2p_mob_dropdown_item');
        } else {
            item?.classList?.add('hide_p2p_mob_dropdown_item');
        }
    });
};

var isValidString = inputStr => {
    let str = String(inputStr)?.toLowerCase();
    if (
        str === 'null' ||
        str === 'undefined' ||
        str === '' ||
        str === 'false' ||
        str === 'no' ||
        str === '0' ||
        str === 'nan'
    ) {
        return false;
    } else if (str === 'true') {
        return true;
    }
    return str;
};

var generateCorrelationId = () => {
    var sessionId = isValidString(cookieValue('guestsessionid')) || uuid4();
    let userId = cookieValue('sbuid') || cookieValue('guest_user_id');
    userId = isValidString(userId) ? userId : uuid4();

    const genericCorrelation = `${userId}${sessionId?.substring(0, 16)}`;
    return genericCorrelation ? `${genericCorrelation}${uuid4()?.substring(0, 8)}` : sessionId;
};

var clearLocalStorage = (conserveKeys = []) => {
    const backupData = {};
    backupData[STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID] = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID);
    for (let key of conserveKeys) {
        backupData[key] = localStorage.getItem(key);
    }
    localStorage.clear();
    for (let key in backupData) {
        localStorage.setItem(key, backupData[key]);
    }
};

var deleteCookies = (exludeCookies = []) => {
    var allCookies = document.cookie
        .split(';')
        ?.filter(cookie => !exludeCookies.includes(cookie.split('=')[0]?.trim()));
    for (var i = 0; i < allCookies.length; i++)
        document.cookie =
            allCookies[i] + '=;expires=' + new Date(0).toUTCString() + '=; path=/; SameSite=None; Secure; Max-Age=0;';
};

var logoutUser = async () => {
    let [magentoToken] = useCookieValue('cif.magentoToken');
    const projectInfoCookies = getProjectsFromCookie();
    if (!magentoToken) {
        magentoToken = sessionStorage.getItem('cif.magentoToken');
    }

    const { graphqlEndpoint, headers = {} } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );
    const options = {
        method: 'POST',
        headers: {
            'x-correlation-Id': generateCorrelationId(),
            Authorization: `Bearer ${magentoToken}`,
            'company-id':
                parseInt(localStorage.getItem('companyID') || projectInfoCookies?.CurrentWynneAccountCID) || 1,
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: MUTATION_REVOKE_TOKEN_STRING,
            fetchPolicy: 'network-only'
        })
    };
    try {
        const result = await fetch(`${window.location.origin}${graphqlEndpoint}`, options);
        const logoutResult = await result.json();
        return logoutResult;
    } catch (err) {
        console.error('revokeCustomerToken error', err);
        return { error: true, error: err };
    }
};

var clearAemCookies = () => {
    return axios({
        method: 'POST',
        url: AEM_COOKIE_CLEAR_ENDPOINT
    });
};

var handleSignout = async () => {
    try {
        const punchoutRedirectURL = useCookieValue('punchout_redirect_url')?.[0] || config.pagePaths.signout;
        const { data, error } = await logoutUser();
        if (!error) {
            await clearAemCookies();
            clearLocalStorage();
            sessionStorage.clear();
            deleteCookies();
            window.parent.location.href = punchoutRedirectURL;
        } else {
            console.error('error in miniaccount.js logoutUser', error);
        }
    } catch (error) {
        console.error('error in miniaccount.js logoutUser', error);
    }
};
handleLogin(useCheckUser());
function addEventListenerWhenAvailable() {
    const signoutP2PButton = document.getElementById('signoutP2P');
    //selecting all buttons (2 are there) with signout class
    const signoutP2PMobileButton = document.querySelectorAll('.signout.mobile_button');
    if (signoutP2PMobileButton?.length) {
        //looping over selected buttons since multiple were there to make sure event is added to all of them
        signoutP2PMobileButton.forEach(button => {
            button.addEventListener('click', handleSignout);
        });
    } else {
        console.log('signout mobile_button not found');
    }
    if (signoutP2PButton) {
        signoutP2PButton.addEventListener('click', handleSignout);
    } else {
        console.log('signoutP2P not found');
    }
}

addEventListenerWhenAvailable();
try {
    document.addEventListener('DOMContentLoaded', event => {
        $('.side__menu-button-account').on('click', function () {
            $(this)?.attr('aria-expanded', true);
            $(this)?.closest('aside')?.toggleClass('hideoverflow');
            $('.side__submenu-account')?.toggleClass('active');
        });
        $('.authenticated_user_mob .back_main_menu').on('click', function () {
            $(this)
                ?.closest('.authenticated_user_mob')
                ?.find('.side__menu-item-button.side__menu-button-account')
                ?.attr('aria-expanded', 'false');

            $(this)?.closest('aside')?.toggleClass('hideoverflow');
            $('.side__submenu-account')?.toggleClass('active');
        });
        window.clearUserProfileStorage = () => {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
        };
    });
} catch (error) {
    console.error('An error occurred while initializing the Jquery:', error);
}
