/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2021 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
 import { gql } from '@apollo/client';

 const QUERY_CART_DETAILS = gql`
     query cartDetails(
         $cartId: String!
         $cartInputData: String
     ) {
         cart(
             cart_id: $cartId
             cart_input_data: $cartInputData
         ) {
             id
             updated_at
             created_at
             total_quantity
             accessoriesnaddons
             items {
                 uid
                 quantity
                 product {
                     name
                     sku
                     product_page_url
                     ec_pc_inventory
                     isOverSize
                     isOverWeight
                     category_name
                     parent_category_name
                     category_path
                     productcatsortindex
                     disableaddtocartoption
                     showonlinecatalog
                     greenleafdata
                     isgreenleaf
                     categories {
                         category_page_url
                         id
                         name
                     }
                     thumbnail {
                         url
                     }
                 }
                 ... on BundleCartItem {
                     bundle_options {
                         id
                         label
                         type
                         values {
                             id
                             label
                             price
                             quantity
                         }
                     }
                 }
             }
         }
     }
 `;
 export default QUERY_CART_DETAILS;
 
