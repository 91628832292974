import { gql } from '@apollo/client';

const MUTATION_BULK_UPDATE_CONSUMABLES = gql`
    mutation bulkupdateCartAccessories($cartId: String!, $accessories: String!) {
        bulkupdateCartAccessories(input: { cart_id: $cartId, accessories: $accessories }) {
            message
        }
    }
`;

export default MUTATION_BULK_UPDATE_CONSUMABLES;